import React from "react";
import { Route, Routes } from "react-router-dom";
import NotFound from "../Pages/NotFound/NotFound";
import Login from "../components/Auth/Login/Login.jsx";
// import Protected from "../protected/protected";
import MainPage from "../Pages/MainPage/MainPage";
import Payments from "../Pages/Payments/Payments.jsx";
import MyGroups from "../Pages/MyGroups/MyGroups.jsx";
import Pointers from "../Pages/Pointers/Pointers.jsx";
import Rating from "./../Pages/Rating/Rating";
import Profile from "../Pages/Profile/Profile.jsx";
import ForgotPassword from "../components/Auth/ForgotPassword/ForgotPassword.jsx";
import UpdatePassword from "../components/Auth/UpdatePassword/UpdatePassword.jsx";
import Reward from "../components/Reward/Reward.jsx";
import TestResult from "../components/TestResult/TestResult.jsx";
import Attendance from "../components/Attendance/Attendance.jsx";
import Appropriation from "../components/Approptiation/Appropriation.jsx";
import TechGroup from "../Pages/teacher/TechGroups/TechGroups.jsx";
import TechLesson from "../Pages/teacher/TechLesson/TechLesson.jsx";
import TechAttendance from "../Pages/teacher/TechAttendance/TechAttendance.jsx";
// import TechHomeworkRating from "../Pages/teacher/TechHomeworkRating/TechHomeworkRating.jsx";
import TechHomework from "../Pages/teacher/TechHomework/TechHomework.jsx";
import TechReward from "../Pages/teacher/TechReward/TechReward.jsx";
import TechAttendanceRating from "../Pages/teacher/TechAttendanceRating/TechAttendanceRating.jsx";
import TechHomeworkRating from "../Pages/teacher/TechHomeworkRating/TechHomeworkRating.jsx";
// // Admin
import AdStudent from "../Pages/admin/AdStudent/AdStudent.jsx";
import AdTeacher from "../Pages/admin/AdTeacher/AdTeacher.jsx";
import AdGroup from "../Pages/admin/AdGroup/AdGroup.jsx";
import AdTest from "../Pages/admin/AdTest/AdTest.jsx";
import AdPayment from "../Pages/admin/AdPayment/AdPayment.jsx";
import AdCost from "../Pages/admin/AdCost/AdCost.jsx";
import AdReward from "../Pages/admin/AdReward/AdReward.jsx";
import AdminSinglePage from "../Pages/admin/AdGroup/AdminSinglePage.jsx";
import AdminSinglePageTest from "../Pages/admin/AdTest/AdminSinglePageTest.jsx";
import AdminPaymentInner from "../Pages/admin/AdPayment/AdminPaymentInner.jsx";
import TechHomeworkInner from "../Pages/teacher/TechHomework/TechHomeworkInner.jsx";
// import SinglePage from "../components/SinglePage/SinglePage";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/forgot" element={<ForgotPassword />} />
        <Route path="/password-update" element={<UpdatePassword />} />
        <Route path="*" element={<NotFound />} />
        <Route path="/logout" element={<Login />} />

        {/* <Route path="/" element={<Protected />}> */}
          <Route path="/" element={<MainPage />} />
          <Route path="/payments" element={<Payments />} />
          <Route path="/my-groups" element={<MyGroups />} />
          <Route path="/pointers" element={<Pointers />}>
            <Route index element={<Reward />} />
            <Route path="test-result" element={<TestResult />} />
          </Route>
          <Route path="/rating" element={<Rating />}>
            <Route index element={<Attendance />} />
            <Route path="appropriation" element={<Appropriation />} />
          </Route>

          <Route path="/profile" element={<Profile />} />
          <Route path="/tech-group" element={<TechGroup />} />
          <Route path="/tech-lesson" element={<TechLesson />} />
          <Route path="/:groupId/tech-lesson" element={<TechLesson />} />
          <Route path="/:groupId/tech-attendance" element={<TechAttendance />} />
          <Route path="/:groupId/tech-homework" element={<TechHomework />} />
          <Route path="/:groupId/tech-homework/:lessonId" element={<TechHomeworkInner/>} />
          <Route path="/:groupId/tech-pointers" element={<TechReward />} />
          <Route path="/:groupId/tech-rating-attendance" element={<TechAttendanceRating />} />
          <Route path="/:groupId/tech-rating-homework" element={<TechHomeworkRating />} />
          {/* <Route path="/tech-attendance" element={<TechAttendance />} /> */}

          {/* ==============  Admin ============== */}
          <Route path="/ad-students" element={<AdStudent />} />
          <Route path="/ad-teachers" element={<AdTeacher />} />
          <Route path="/ad-groups" element={<AdGroup />} >
          </Route>
            <Route path="/ad-groups/:id" element={<AdminSinglePage />} />
          <Route path="/ad-tests" element={<AdTest />} >
          </Route>
            <Route path="/ad-tests/:id" element={<AdminSinglePageTest />} />
          <Route path="/ad-payments" element={<AdPayment />} />
          <Route path="/ad-payments/:studentId" element={<AdminPaymentInner />} />
          <Route path="/ad-costs" element={<AdCost />} />
          <Route path="/ad-rewards" element={<AdReward />} />

        {/* </Route> */}
      </Routes>
    </div>
  );
}

export default App;

import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Info } from "@mui/icons-material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function TechGroupModel(data) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  console.log(data.data, "modal");

  return (
    <div>
      <Button onClick={handleOpen}>
        <Info />
      </Button>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <Typography
              id="transition-modal-title"
              variant="h4"
              component="h2"
              sx={{ mb: 2 }}
            >
              Yo`nalish
            </Typography>
            <Typography
              id="transition-modal-description"
              variant="h6"
              sx={{ mb: 2 }}
            >
               {data.data.tag_name}
            </Typography>
            <Typography
              id="transition-modal-title"
              variant="h4"
              component="h2"
              sx={{ mb: 2 }}
            >
              Boshlangan sana
            </Typography>
            <Typography
              id="transition-modal-description"
              variant="h6"
              component="h4"
              sx={{ mb: 2 }}
            >
              {data.data.start_date.split("T")[0]}
            </Typography>
            <Typography id="transition-modal-title" variant="h4" component="h2">
              Tugagan sana
            </Typography>
            <Typography
              id="transition-modal-description"
              variant="h6"
              component="h4"
            >
              {data.data.end_date !== "" ? data.data.end_date.split("T")[0] : "Noma'lum"}
            </Typography>
            <Typography
              id="transition-modal-title"
              variant="h4"
              component="h2"
              sx={{ mb: 2 }}
            >
              Dars vaqti
            </Typography>
            <Typography
              id="transition-modal-description"
              variant="h6"
              component="h4"
              sx={{ mb: 2 }}
            >
              {data.data.start_time.split("T")[1].slice(0, -4)}-{data.data.end_time.split("T")[1].slice(0, -4)}
            </Typography>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}

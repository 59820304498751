import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "../../../lib/axios";
import "./AdminSinglePageTest.scss";
import { Delete, KeyboardReturn } from "@mui/icons-material";
// import AdminUpdateStudentTest from "./AdminUpdateStudentTest";
import AdminAddAnotherStudentToTest from "./AdminAddAnotherStudentToTest";
import AdminAddStudentToTest from "./AdminAddStudentToTest";
import AdminViewTestInner from "./AdminViewTestInner";

export default function AdminSinglePageTest() {
  const useAuth = window.localStorage;
  const { id: testID } = useParams();
  const Token = useAuth.getItem("accessToken");
  const [students, setStudents] = useState();
  const [test, setTest] = useState();
  const navigate = useNavigate();

  const config = {
    headers: {
      Authorization: Token,
    },
  };

  try {
    useEffect(() => {
      axios.get(`test/sort/${testID}`, config).then((response) => {
        console.log(response.data);
        response.data && setStudents(response.data.student_tests);
      });
    }, [testID]);
  } catch {
    console.log("error");
  }

  try {
    useEffect(() => {
      axios
        .get(`test/${testID}`, config)
        .then((response) => {
          console.log(response.data);
          setTest(response.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }, [testID]);
  } catch (error) {
    if (error.response.status === 401) {
      navigate("/logout");
    }
  }

  const deleteLesson = (studentId) => {
    if (confirm("O'chirishni istaysizmi?")) {
      axios
        .delete(`test/student?sid=${studentId}&tid=${testID}`, config)
        .then(() => {
          window.location.reload();
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const toggleAction = (e) => {
    const actionBody = e.target.nextSibling;
    console.log(actionBody, "actionBody");
    if (actionBody.style.display === "block") {
      actionBody.style.display = "none";
    } else {
      actionBody.style.display = "block";
    }
  };
  return (
    <>
      <section className="admin-single-group-section">
        <div className="container">
          <div className="admin-single">
            <Link to="/ad-tests" className="admin-single__header">
              <h1 className="admin-single__title">{test?.test_info}</h1>
              <KeyboardReturn/>
            </Link>
            {test && <div className="admin-single__wrapper">
              <div className="admin-single__teacher">
                <strong>Max ball</strong>: {test?.max_score}
              </div>
              <div>
                <strong>Sana: </strong> {test?.test_date.split("T")[0]}
              </div>
            </div>}
            {test === undefined ? (
          <section className="mygroup-section tech-lesson-section">
          </section>
            ) : (
          <section className="mygroup-section tech-lesson-section admin-group-section ad-teacher">
            <div className="container">
              <div className="mygroup">
                <table className="mygroup__table desktop">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Talabalar</th>
                      <th>Talaba to`plagan ball</th>
                      <th>Foiz</th>
                      <th className="add-wrapper">
                        <AdminAddStudentToTest />
                        <AdminAddAnotherStudentToTest />
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {students &&
                      students.map((item, index) => (
                        <tr key={index} id={item.student_id}>
                          <td>{index + 1}</td>
                          <td id="lessonName">
                              {item.student_first_name} {item.student_last_name}
                          </td>
                          <td>{item.student_score}</td>
                          <td>{Math.round(item.student_percent)}</td>
                          <td className="add-wrapper">
                              {/* <AdminUpdateStudentTest data={item}/> */}
                            <button
                              className="btn-delete"
                              onClick={() => deleteLesson(item.student_id)}
                              style={{ color: "crimson" }}
                            >
                              <Delete />
                            </button>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>

                <div className="mygroup__table mobile">
                  <div className="mygroup__table-head">
                    <div className="">#</div>
                    <div className="">Talabalar</div>
                    <AdminAddStudentToTest />
                  </div>
                  <div className="mygroup__table-body">
                    {students &&
                      students.map((item, index) => (
                        <div key={index} className="mygroup__table-body--item">
                          <div className=""><p>{index + 1}</p></div>
                          <div className="group-name">
                            <p>{item.student_first_name} {item.student_last_name}</p>
                            </div>
                            <div>
                              <button className="btn-action"
                                onClick={ toggleAction}>
                                  •••
                              </button>
                              <div className="ad-teacher-action-body">
                                <AdminViewTestInner data={item}/>
                              <AdminAddAnotherStudentToTest />
                                <button
                                  className="btn-delete"
                                  onClick={() => deleteLesson(item.id)}
                                  style={{ color: "crimson" }}
                                >
                                  <Delete />
                                </button>
                              </div>
                            </div>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          </section>
            )}
          </div>
        </div>
      </section>
    </>
  );
}

import React, { useEffect, useState } from "react";
import axios from "../../../lib/axios";
// import ViewStudent from "./AdminViewPayment";
import "./AdPayment.scss";

import { Delete, KeyboardReturn } from "@mui/icons-material";
// import TechAddLesson from "./AdminAddPayment";
import TechUpdateLesson from "./AdminUpdatePayment";
import { Link, useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import AdminAddPaymentInner from "./AdminAddPaymentInner";
import AdminViewPaymentInner from "./AdminViewPaymentInner";
import Pagination from "@mui/material/Pagination"; // Import Pagination
import Stack from "@mui/material/Stack"; // Impo
export default function AdminPaymentInner() {
  const useAuth = window.localStorage;
  const navigate = useNavigate();
  const Token = useAuth.getItem("accessToken");
  const [students, setStudents] = useState();
  const [change, setChange] = useState(false);
  const { studentId } = useParams();
  const [page, setPage] = useState(1); // Current page state
  const [totalPages, setTotalPages] = useState(1); // Total pages state
  const [limit] = useState(10); // Number of items per page

  const config = {
    headers: {
      Authorization: Token,
    },
  };

  useEffect(() => {
    axios
      .get(`/payment/student-list?page=${page}&limit=${limit}`, config)
      .then((response) => {
        // console.log(response.data.students);
        setStudents(response.data.students.find(a => a.student_id === studentId));
        setTotalPages(response.data.total_count < limit ? 1 : Math.ceil(response.data.total_count / limit)); // Assuming your API returns the total number of pages
      })
      .catch((err) => {
        if (err.response.status === 401) {
          navigate("/logout");
        }
        console.log(err);
      });
  }, [page]);

  const deleteLesson = (id) => {
    console.log(students);
    if (confirm("O'chirishni istaysizmi?")) {
      axios
        .delete(`/payment/pay/${id}`, config)
        .then(() => {
          window.location.reload();
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  // const getPayments = (id) => {
  //   useEffect(() => {
  //     axios.get(`payment/student-pays?s-date=${"DD%2FMM%2FYYYY"}&e-date=DD%2FMM%2FYYYY&sid=${id}&page=1&limit=50`, config).then((data) => {
  //       setPayments(data.data.payments);
  //     }).catch((err) => {
  //       console.log(err);
  //     });
  //   }, [id]);
  // };

  const toggleAction = (e) => {
    const actionBody = e.target.nextSibling;
    console.log(actionBody, "actionBody");
    if (actionBody.style.display === "block") {
      actionBody.style.display = "none";
    } else {
      actionBody.style.display = "block";
    }
  };

  const handlePageChange = (event, value) => {
    setPage(value); // Update the page state when user changes the page
  };

  return (
    <>
      {/* <AdNavbar /> */}
      {/* <main className="mainPage"> */}
        <section className="mygroup-section tech-lesson-section ad-teacher">
            <div className="container">
              <div className="mygroup">
                <Link to="/ad-payments" className="admin-single__header">
                  <h1 className="admin-single__title">{students && students.student_first_name + " " + students.student_last_name}</h1>
                  <KeyboardReturn/>
                </Link>
                <table className="mygroup__table desktop">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Guruh nomi</th>
                      <th>To`lov miqdori</th>
                      <th>To`lov sanasi</th>
                      <th>To`lov turi</th>
                      <th className="add-wrapper">
                        <AdminAddPaymentInner />
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {students &&
                      students.student_payments.map((item, index) => (
                        <tr key={index} id={item.id}>
                          <td>{index + 1}</td>
                          <td><p>{item.group.group_name}</p></td>
                          <td id="lessonName">
                            <p onClick={() => setChange(!change)}>
                              {item.payment_amount}
                            </p>
                          </td>
                          <td>
                            <p>{moment(item.payment_date).format("DD/MM/YYYY")}</p>
                          </td>
                          <td><p>{item.payment_type === "cash" ? "Naqd" : "Plastik"}</p></td>
                          <td className="add-wrapper">
                            {/* <ViewStudent data={item}/> */}
                            <TechUpdateLesson data={item} />
                            <button
                              className="btn-delete"
                              onClick={() => deleteLesson(item.payment_id)}
                              style={{ color: "crimson" }}
                            >
                              <Delete />
                            </button>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>

                <div className="mygroup__table mobile">
                  <div className="mygroup__table-head">
                    <div className="">#</div>
                    <div className="">Guruh nomi</div>
                    <AdminAddPaymentInner />
                  </div>
                  <div className="mygroup__table-body">
                    {students &&
                      students.student_payments.map((item, index) => (
                        <div key={index} className="mygroup__table-body--item">
                          <div className=""><p>{index + 1}</p></div>
                          <div className="group-name">
                          <p>{item.group.group_name}</p>
                            </div>
                            <div>
                              <button className="btn-action"
                                onClick={ toggleAction}>
                                  •••
                              </button>
                              <div className="ad-teacher-action-body">
                                <AdminViewPaymentInner data={item} />
                                <TechUpdateLesson data={item} />
                                <button
                                  className="btn-delete"
                                  onClick={() => deleteLesson(item.id)}
                                  style={{ color: "crimson" }}
                                >
                                  <Delete />
                                </button>
                              </div>
                            </div>
                        </div>
                      ))}
                  </div>
                </div>
                <Stack spacing={2} alignItems="center" marginTop="20px">
                  <Pagination
                    count={totalPages}
                    page={page}
                    onChange={handlePageChange}
                    color="secondary"
                    variant="outlined"
                    shape="rounded"
                  />
                </Stack>
              </div>
            </div>
          </section>
      {/* </main> */}
    </>
  );
}

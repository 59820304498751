import React, { useEffect, useState } from "react";
import "./TechHomework.scss";
import axios from "../../../lib/axios";
import { NavLink, useParams } from "react-router-dom";
import TestNavbar from "../TechNavbar/TechNavbar";
import MyPopUp from "./MyPopUp";
import Pagination from "@mui/material/Pagination"; // Import Pagination
import Stack from "@mui/material/Stack"; // Impo

function TechHomework() {
  const useAuth = window.localStorage;
  const userId = useAuth.getItem("guid");
  const Token = useAuth.getItem("accessToken");
  // const [attendance, setAttendance] = useState(undefined);
  const [lessons, setLessons] = useState();
  const { groupId } = useParams();
  const [page, setPage] = useState(1); // Current page state
  const [totalPages, setTotalPages] = useState(1); // Total pages state
  const [limit] = useState(10); // Number of items per page

  const config = {
    headers: {
      Authorization: Token,
    },
  };

  useEffect(() => {
    const id = localStorage.getItem("group_id");
    id &&
      axios
        .get(
          `lesson/group-lesson?gid=${id}&page=${page}&limit=${limit}&sid=${userId}`,
          config,
        )
        .then((response) => {
          setLessons(response.data.lessons);
          setTotalPages(response.data.total_count < limit ? 1 : Math.ceil(response.data.total_count / limit)); // Assuming your API returns the total number of pages
        })
        .catch((err) => {
          console.log(err);
        });

    // handleHomework();
  }, [page]);

  console.log(lessons);

  const points = () => {
    const groupId = localStorage.getItem("group_id");
    axios
      .get(`/lesson-points?gid=${groupId}&lid=${lessons}`, config)
      .then((response) => {
        console.log(response);
        // setNoPoint(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  points();
  // const lessonClick = (item, point) => {
  //   setLesson(item);
  //   console.log(item, "item");
  //   console.log(point, "Point");
  //   axios
  //     .get("/groups/students/" + item.group_id, config)
  //     .then((response) => {
  //       setAttendance(response.data.students);
  //       const data = [];
  //       try {
  //         handleHomework();
  //         console.log(attendance, "attendance");
  //         points();
  //         console.log(data, "data");
  //         console.log(noPoint, "noPoint");
  //         setNoPoint(data);
  //         console.log(noPoint, "noPoint");
  //         data.length = 0;
  //       } catch (error) {
  //         console.log(error.message);
  //       }
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  //   console.log(homework, "homework");
  // };

  const handlePageChange = (event, value) => {
    setPage(value); // Update the page state when user changes the page
  };

  return (
    <>
      <TestNavbar />
      <main className="mainPage">
        {
          <section className="mygroup-section tech-lesson-section">
            <div className="container">
              <div className="mygroup">
                <table className="mygroup__table desktop">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Dars nomi</th>
                      <th>Dars sana</th>
                    </tr>
                  </thead>
                  <tbody>
                    {lessons &&
                      lessons.map((item, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td id="lessonName">
                            <NavLink to={`/${groupId}/tech-homework/${item.id}`}>
                            {item.lesson_name}
                            </NavLink>
                          </td>
                          <td id="lessonDate">
                            {item.lesson_date.split("T")[0]}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>

                <div className="mygroup__table mobile tech-attendance">
                  <div className="mygroup__table-head">
                    <div className="">#</div>
                    <div className="">Dars nomi</div>
                    <div>Dars sanasi</div>
                  </div>
                  <div className="mygroup__table-body">
                    {lessons &&
                      lessons.map((item, index) => (
                        <div key={index} className="mygroup__table-body--item">
                          <div style={{ alignSelf: "center", padding: "25px 15px" }} className="">{index + 1}</div>
                          <div style={{ alignSelf: "center", padding: "25px 15px" }} className="group-name">
                            <NavLink to={`/${groupId}/tech-homework/${item.id}`}>
                            {item.lesson_name}
                            </NavLink>
                            </div>
                          <div><MyPopUp data={item}/></div>
                        </div>
                      ))}
                  </div>
                </div>
                <Stack spacing={2} alignItems="center" marginTop="20px">
                  <Pagination
                    count={totalPages}
                    page={page}
                    onChange={handlePageChange}
                    color="secondary"
                    variant="outlined"
                    shape="rounded"
                  />
                </Stack>
              </div>
            </div>
          </section>
        }
      </main>
    </>
  );
}

export default TechHomework;

import React, { useEffect, useState } from "react";
import "./TechReward.scss";
import axios from "../../../lib/axios";
import { NavLink } from "react-router-dom";
import TechNavbar from "../TechNavbar/TechNavbar";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import TechRewardModal from "./TechModalReward";

function TechReward() {
  const useAuth = window.localStorage;
  const Token = useAuth.getItem("accessToken");
  const [students, setStudents] = useState([]);
  const [change, setChange] = useState(false);
  const [reward, setReward] = useState();

  const config = {
    headers: {
      Authorization: Token,
    },
  };

  useEffect(() => {
    axios
      .get("/groups/students/" + localStorage.getItem("group_id"), config)
      .then((response) => {
        setStudents(response.data.students);
        setReward();
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  function handleClick(index) {
  }

  console.log(students, "students");

  return (
    <>
      <TechNavbar />
      <main className="mainPage">
        {reward !== undefined ? (
          <section className="mygroup-section tech-lesson-section">
            <div className="container">
              <div className="mygroup tech-lesson">
                <table className="mygroup__table desktop">
                  <thead>
                    <tr>
                      <th style={{ width: "50px" }} className="tablet">
                        #
                      </th>
                      <th className="tablet">Talabalar</th>
                      <th style={{ width: "120px" }}>
                        <EmojiEventsIcon htmlColor="#FFD700" />
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {students.map((item, index) => (
                      <tr key={index} id="margin">
                        <td className="tablet">{index + 1}</td>
                        <td className="tablet">
                          <NavLink onClick={() => handleClick(index)}>
                            {item.group_name}
                          </NavLink>
                        </td>
                        <td>Mukofotlar</td>
                      </tr>
                    ))}
                  </tbody>
                </table>

                <div className="mygroup__table mobile">
                  <div className="mygroup__table-head">
                    <div className="">#</div>
                    <div className="">Guruh nomi</div>
                  </div>
                  <div className="mygroup__table-body">
                    {students.map((item, index) => (
                      <div key={index} className="mygroup__table-body--item">
                        <div className="">{index + 1}</div>
                        <div className="group-name">
                          <NavLink onClick={() => handleClick(index)}>
                            {item.group_name}
                          </NavLink>
                        </div>
                        <div>{/* <ModalCourseInfo data={item} /> */}</div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </section>
        ) : (
          <section className="mygroup-section tech-lesson-section">
            <div className="container">
              <div className="mygroup">
                <table className="mygroup__table desktop">
                  <thead>
                    <tr>
                      <th style={{ width: "120px" }}>#</th>
                      <th>Talabalar</th>
                      <th style={{ width: "120px", padding: "0 25px" }}>
                        <EmojiEventsIcon htmlColor="#FFD700" />
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {students &&
                      students.map((item, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td id="lessonName">
                            <p onClick={() => setChange(!change)}>
                              {item.first_name} {item.last_name}
                            </p>
                          </td>
                          <td id="lessonDate">
                            <TechRewardModal data={item} />
                            {/* {item.lesson_date.split("T")[0]} */}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>

                <div className="mygroup__table mobile">
                  <div className="mygroup__table-head">
                    <div className="">#</div>
                    <div className="">Dars nomi</div>
                    <div style={{ paddingRight: "25px" }}><EmojiEventsIcon htmlColor="#FFD700" /></div>
                  </div>
                  <div className="mygroup__table-body">
                    {students &&
                      students.map((item, index) => (
                        <div key={index} className="mygroup__table-body--item">
                          <div className="" style={{ padding: "14px" }}><p>{index + 1}</p></div>
                          <div className="" style={{ padding: "14px" }}>
                            <p>{item.first_name} {item.last_name}</p>
                          </div>
                          <div><TechRewardModal data={item} /></div>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          </section>
        )}
      </main>
    </>
  );
}

export default TechReward;

import React, { useEffect, useState } from "react";
import "./MainPage.scss";
import Header from "../../components/Header/Header";
import axios from "../../lib/axios";
import { useNavigate } from "react-router-dom";
import CircularProgress from "../../components/UI/Chart/circle";

function MainPage() {
  const TOKEN = window.localStorage.getItem("accessToken");
  const navigate = useNavigate();
  const STUDENT_ID = window.localStorage.getItem("guid");
  const ROLE = window.localStorage.getItem("role");
  const [data, setData] = useState();
  const [stats, setStats] = useState();

  const config = {
    headers: {
      Authorization: `${TOKEN}`, // Assuming token is a bearer token
    },
  };

  ROLE === "teacher" && navigate("/tech-group");

  useEffect(() => {
    axios
      .get(`/student/home/${STUDENT_ID}`, config)
      .then((data) => {
        setData(data.data);
      })
      .catch((err) => {
        if (err.response.status === 401) {
          navigate("/logout");
        }
        console.log(err.response);
      });
  }, []);
  useEffect(() => {
    axios.get(`/stats/percent/${STUDENT_ID}`, config).then((data) => {
      setStats(data.data);
    });
  }, []);
  console.log(stats);
  return (
    <>
      {ROLE !== "admin" ? (
        <>
          <Header />
          <main className="mainPage">
            <section className="subject-page">
              <div className="container">
                <div className="subject">
                  <ul className="flex-without-space">
                    {data?.map((item, index) => (
                      <li key={index}>
                        <h2 className="subject__name">{item.tagname}</h2>
                        {item.groups?.map((item, index) => (
                          <p key={index}>{item}</p>
                        ))}
                      </li>
                    ))}
                  </ul>
                  <hr />
                  {stats !== undefined ? (
                  <div className="stats-wrapper">
                    <CircularProgress percentage={stats.weekly} title="Haftalik" />
                    <CircularProgress percentage={stats.monthly} title="Oylik"/>
                    <CircularProgress percentage={stats.total} title="Umumiy"/>
                  </div>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </section>
          </main>
        </>
      ) : (
        navigate("/ad-students")
      )}
    </>
  );
}

export default MainPage;

import React, { useEffect, useState } from "react";
import "./UpdatePassword.scss";
import { NavLink, useNavigate } from "react-router-dom";
import axios from "../../../lib/axios";

function UpdatePassword() {
  const [newPassword, setNewPassword] = useState(1);
  const [reEnterPassword, setReEnterPassword] = useState(-1);
  const [check, setCheck] = useState(false);
  const navigate = useNavigate();

  const submit = () => {
    if (window.localStorage.getItem("login")) {
      alert("Parol muvaffaqiyatli yangilandi");
    } else {
      alert("Xatolik yuz berdi");
      navigate("/logout");
    }
    setCheck(pre => !pre);
  };

  const password = (e) => {
    e.preventDefault();
    setNewPassword(e.target.value);
  };

  const rePassword = (e) => {
    e.preventDefault();
    setReEnterPassword(e.target.value);
  };

  const login = window.localStorage.getItem("login");

  useEffect(() => {
    if (check && newPassword === reEnterPassword) {
      const link = `/update-password?login=${login}&password=${newPassword}&confirm=${reEnterPassword}`;
      // console.log(link);
      axios.put(link,
      ).then((result) => {
        console.log(result);
        if (result.data) {
          navigate("/logout");
          window.localStorage.clear("login");
        }
      },
      ).catch(() => {});
    };
  }, [check]);

  return (
    <>
      <section className="password-section">
        <div className="container">
          <div className="password">
            <div className="password__wrapper">
              <h2>Yangi parol yaratish</h2>
              <label>
                Yangi parol kiriting
                <input
                  type="text"
                  className="update-password"
                  autoFocus
                  onChange={password}
                />
              </label>
              <label>
                Parolni qayta kiriting
                <input
                  type="text"
                  className="update-password"
                  onChange={rePassword}
                />
              </label>
            </div>
            <div className="password__btn">
              <NavLink to="/forgot">Ortga qaytish</NavLink>
              <input
                type="submit"
                onClick={submit}
                value={"Parolni yangilash"}
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default UpdatePassword;

import React, { useEffect, useState } from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import { Add } from "@mui/icons-material";
import axios from "../../../lib/axios";
import TextField from "@mui/material/TextField";
import moment from "moment";
import { useParams } from "react-router-dom";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: 500,
  width: "100%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  zIndex: 10,
};

export default function AdminAddPaymentInner() {
  const Token = localStorage.getItem("accessToken");
  const [open, setOpen] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [groupId, setGroupId] = useState("");
  const [paymentAmount, setPaymentAmount] = useState();
  const [paymentDate, setPaymentDate] = useState("");
  const [paymentType, setPaymentType] = useState("");
  // const [studentId, setStudentId] = useState("");
  // const [students, setStudents] = useState([]);
  const [groups, setGroups] = useState([]);
  const { studentId } = useParams();

  const config = {
    headers: {
      Authorization: Token,
    },
  };

  useEffect(() => {
    axios.get(`/groups/${studentId}`, config).then((response) => {
      console.log(response.data);
      setGroups(response.data.groups);
    });
  }, []);

  const createStudent = (e) => {
    e.preventDefault();
    const formData = {
      group_id: groupId,
      payment_amount: paymentAmount,
      payment_date: moment(paymentDate).format("MM/DD/YYYY"),
      payment_type: paymentType,
      student_id: studentId,
    };

    axios
      .post("/payment/pay", formData, config)
      .then(() => {
        window.location.reload();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (
      groupId &&
      paymentAmount &&
      paymentDate &&
      paymentType &&
      studentId &&
      groupId
    ) {
      setDisabled(false);
    }
  }, [disabled]);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
      <Button onClick={handleOpen}>
        <Add />
      </Button>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box component="form" sx={style} noValidate autoComplete="off">
            <div
              onSubmit={createStudent}
              style={{
                display: "flex",
                flexWrap: "wrap",
                rowGap: "20px",
                columnGap: "20px",
              }}
            >
              <TextField
                required
                label="To'langan summa"
                type="text"
                style={{ width: "100%" }}
                value={paymentAmount}
                onChange={(e) => setPaymentAmount(Number(e.target.value))}
                />
              <TextField
                required
                type="date"
                style={{ width: "100%" }}
                value={paymentDate}
                onChange={(e) => setPaymentDate(e.target.value)}
                autoFocus
              />
              <TextField
                required
                style={{ width: "100%" }}
                value={paymentType}
                onChange={(e) => {
                  setPaymentType(e.target.value);
                }}
                select
                SelectProps={{
                  native: true,
                }}
              >
                <option hidden>To`lov turini tanlang</option>
                <option value="cash">Naqd</option>
                <option value="card">Plastik</option>
              </TextField>
              <TextField
                required
                style={{ width: "100%" }}
                value={groupId}
                onChange={(e) => {
                  setGroupId(e.target.value);
                  console.dir(e.target.selectedOptions[0].innerText);
                }}
                select
                SelectProps={{
                  native: true,
                }}
              >
                <option hidden>Guruhni tanlang</option>
                {groups &&
                  groups.map((item) => (
                    <option key={item.id} value={item.id}>
                      {item.group_name}
                    </option>
                  ))}
              </TextField>
              <Button variant="contained" style={{ width: "100%" }} onClick={createStudent}>
                Yaratish
              </Button>
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}

import React, { useEffect, useState } from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import { Add } from "@mui/icons-material";
import axios from "../../../lib/axios";
import TextField from "@mui/material/TextField";
import { useParams } from "react-router-dom";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: 500,
  width: "100%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  zIndex: 10,
};

export default function AdminAddStudentToGroup() {
  const Token = localStorage.getItem("accessToken");
  const [open, setOpen] = useState(false);
  // const [groupId, setGroupId] = useState("");
  const [studentId, setStudentId] = useState("");
  const [students, setStudents] = useState([]);
  // const [groups, setGroups] = useState([]);
  const { id: groupId } = useParams();

  const config = {
    headers: {
      Authorization: Token,
    },
  };

  useEffect(() => {
    axios.get("/student/select", config).then((response) => {
      setStudents(response.data);
    });

    // axios.get("/groups", config).then((response) => {
    //   setGroups(response.data.groups);
    // });
  }, []);

  const createStudent = (e) => {
    e.preventDefault();
    const formData = {
      group_id: groupId,
      student_id: studentId,
    };

    axios
      .post("/group/add-student", formData, config)
      .then(() => {
        window.location.reload();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
      <Button onClick={handleOpen}>
        <Add />
      </Button>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box component="form" sx={style} noValidate autoComplete="off">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                rowGap: "20px",
                columnGap: "20px",
              }}
            >
              <TextField
                required
                style={{ width: "100%" }}
                value={studentId}
                onChange={(e) => {
                  setStudentId(e.target.value);
                }}
                select
                SelectProps={{
                  native: true,
                }}
              >
                <option hidden>Stundent tanlang</option>
                {students &&
                  students.map((item) => (
                    <option key={item.student_id} value={item.student_id}>
                      {item.student_name}
                    </option>
                  ))}
              </TextField>
              <Button
                variant="contained"
                style={{ width: "100%" }}
                onClick={createStudent}
              >
                Qo`shish
              </Button>
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}

import React, { useEffect, useState } from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import { Add } from "@mui/icons-material";
import axios from "../../../lib/axios";
import TextField from "@mui/material/TextField";
import moment from "moment";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: 500,
  width: "100%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  zIndex: 10,
};

export default function AdminAddCost() {
  const Token = localStorage.getItem("accessToken");
  const [open, setOpen] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [_costDate, setCostDate] = useState("");
  const [_description, setDescription] = useState("");
  const [_incomingAmount, setIncomingAmount] = useState();
  const [_outComingAmount, setOutComingAmount] = useState();

  const config = {
    headers: {
      Authorization: Token,
    },
  };

  const createStudent = (e) => {
    e.preventDefault();
    const formData = {
      cost_date: moment(_costDate)
        .format("MM/DD/YYYY"),
      description: _description,
      incoming_amount: _incomingAmount,
      out_coming_amount: _outComingAmount,
    };

    axios
      .post("/payment/expenses", formData, config)
      .then((response) => {
        console.log(response.data);
        setDisabled(false);
        window.location.reload();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (
      _costDate &&
      _description &&
      _incomingAmount &&
      _outComingAmount &&
      _costDate
    ) {
      setDisabled(false);
    }
  }, [disabled]);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
      <Button onClick={handleOpen}>
        <Add />
      </Button>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box component="form" sx={style} noValidate autoComplete="off">
            <div
              onSubmit={createStudent}
              style={{
                display: "flex",
                // flexWrap: "wrap",
                flexDirection: "column",
                rowGap: "20px",
              }}
            >
              <TextField
                required
                // label="Cost Date"
                autoFocus
                style={{ width: "100%" }}
                value={_costDate}
                type="date"
                onChange={(e) => setCostDate(e.target.value)}
              />
              <TextField
                required
                label="Tavsifi"
                style={{ width: "100%" }}
                value={_description}
                onChange={(e) => setDescription(e.target.value)}
              />
              <TextField
                required
                label="Krim"
                style={{ width: "100%" }}
                type="text"
                value={_incomingAmount}
                onChange={(e) => setIncomingAmount(Number(e.target.value) ? Number(e.target.value) : "")}
                error={_incomingAmount <= 0}
                helperText={
                  _incomingAmount <= 0 ? "Summani to'g'ri kiriting" : ""
                }
              />
              <TextField
                required
                label="Chiqim"
                style={{ width: "100%" }}
                type="text"
                value={_outComingAmount}
                onChange={(e) => setOutComingAmount(Number(e.target.value) ? Number(e.target.value) : "")}
                error={_outComingAmount <= 0}
                helperText={
                  _outComingAmount <= 0 ? "Summani to'g'ri kiriting" : ""
                }
              />
              <Button variant="contained" style={{ width: "100%" }} onClick={createStudent}>
                Create Cost
              </Button>
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}

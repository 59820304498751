import React from "react";
import "./Pointers.scss";
import Header from "../../components/Header/Header";
import { NavLink, Outlet } from "react-router-dom";

function Pointers() {
  return (
    <>
      <Header />
      <main className="mainPage">
        <section className="pointer-section">
          <div className="container">
            <div className="pointer">
              <div className="pointer__wrapper">
                <NavLink to="/pointers">Mukofot</NavLink>
                <NavLink to="test-result">Test natijalari</NavLink>
              </div>
              <Outlet/>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}

export default Pointers;

import React, { useEffect, useState } from "react";
import "./MyGroups.scss";
import Header from "../../components/Header/Header";
import axios from "../../lib/axios";
import { NavLink } from "react-router-dom";
import MyModel from "../../components/UI/MyModel/MyModel";
import ModalCourseInfo from "./ModalCourseInfo";
import ModalLessonInfo from "./ModalLessonInfo";
import Pagination from "@mui/material/Pagination"; // Import Pagination
import Stack from "@mui/material/Stack"; // Impo

function MyGroups() {
  const useAuth = window.localStorage;
  const userId = useAuth.getItem("guid");
  const Token = useAuth.getItem("accessToken");
  const [groups, setGroup] = useState([]);
  const [lesson, setLesson] = useState();
  const [change, setChange] = useState(false);
  const [index, setIndex] = useState();
  const [page, setPage] = useState(1); // Current page state
  const [totalPages, setTotalPages] = useState(1); // Total pages state
  const [limit] = useState(10); // Number of items per page

  const config = {
    headers: {
      Authorization: Token,
    },
  };

  useEffect(() => {
    localStorage.removeItem("group_id");
    axios
      .get(`groups/${userId}`, config)
      .then((response) => {
        setGroup(response.data.groups);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  function handleClick(index) {
    localStorage.setItem("group_id", groups[index].id);
    setIndex(index);
  }

  useEffect(() => {
    const id = localStorage.getItem("group_id");
    id &&
      axios
        .get(
          `lesson/group-lesson?gid=${id}&page=${page}&limit=${limit}&sid=${userId}`,
          config,
        )
        .then((response) => {
          setLesson(response.data.lessons);
          console.log(response.data);
          setTotalPages(response.data.total_count < limit ? 1 : Math.ceil(response.data.total_count / limit)); // Assuming your API returns the total number of pages
        })
        .catch((err) => {
          console.log(err);
        });
  }, [change, index, page]);

  console.log(lesson, "lesson");
  console.log(groups, "groups");

  const handlePageChange = (event, value) => {
    setPage(value); // Update the page state when user changes the page
  };

  return (
    <>
      <Header />
      <main className="mainPage">
        {lesson === undefined ? (
          <section className="mygroup-section">
            <div className="container">
              <div className="mygroup">
                {/* <div className="mygroup__top flex">
                <select name="" id="status-group">
                  <option value="faol">Faol</option>
                  <option value="tugagan" defaultValue>
                    Tugagan
                  </option>
                </select>
                <input
                  type="search"
                  name="search-group"
                  id="search-group"
                  placeholder="Guruhlarni qidirsh"
                />
              </div> */}

                <table className="mygroup__table desktop">
                  <thead>
                    <tr>
                      <th className="tablet">#</th>
                      <th className="tablet">Guruh nomi</th>
                      <th>Ustoz</th>
                      <th>Dars vaqti</th>
                      <th>Boshlangan sana</th>
                      <th>Tugagan sana</th>
                    </tr>
                  </thead>
                  <tbody>
                    {groups.map((item, index) => (
                      <tr key={index} id="margin">
                        <td className="tablet">{index + 1}</td>
                        <td className="tablet">
                          <NavLink onClick={() => handleClick(index)}>
                            {item.group_name}
                          </NavLink>
                        </td>
                        <td>{item.teacher_name}</td>
                        <td>
                          {item.start_time.split("T")[1].slice(0, -4)}-
                          {item.end_time.split("T")[1].slice(0, -4)}
                        </td>
                        <td>{item.start_date.split("T")[0]}</td>
                        <td>{item.end_date ? item.end_date.split("T")[0] : "Noma'lum"}</td>
                      </tr>
                    ))}
                    {/* <td>
                    <button className="btn-delete">{TramSharp}</button>
                  </td>
                  <td>
                    <button className="btn-edit">{edit}</button>
                  </td>
                  <td>
                    <button className="btn-join">{plus}</button>
                  </td> */}
                  </tbody>
                </table>

                <div className="mygroup__table mobile">
                  <div className="mygroup__table-head">
                    <div className="">#</div>
                    <div className="">Guruh nomi</div>
                    <div></div>
                  </div>
                  <div className="mygroup__table-body">
                    {groups.map((item, index) => (
                      <div key={index} className="mygroup__table-body--item">
                        <div style={{ padding: "23px" }} className="">{index + 1}</div>
                        <div style={{ padding: "21px" }} className="student-group-name">
                          <NavLink onClick={() => handleClick(index)}>
                            {item.group_name}
                          </NavLink>
                        </div>
                        <div>
                          <ModalCourseInfo data={item} />
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </section>
        ) : (
          <section className="mygroup-section">
            <div className="container">
              <div className="mygroup">
                <table className="mygroup__table desktop">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Dars nomi</th>
                      <th>Dars sana</th>
                      <th>Vazifa</th>
                      <th>Ball</th>
                    </tr>
                  </thead>
                  <tbody>
                    {lesson &&
                      lesson.map((item, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>
                            <p onClick={() => setChange(!change)}>
                              {item.lesson_name}
                            </p>
                          </td>
                          <td>{item.lesson_date.split("T")[0]}</td>
                          <td>
                            <MyModel data={item.description} />
                          </td>
                          <td>
                            {item.description === "undefined" ? "-" : item.point}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>

                <div className="mygroup__table mobile">
                  <div className="mygroup__table-head">
                    <div className="">#</div>
                    <div className="">Dars nomi</div>
                  </div>
                  <div className="mygroup__table-body">
                    {lesson &&
                      lesson.map((item, index) => (
                        <div key={index} className="mygroup__table-body--item">
                          <div className="">{index + 1}</div>
                          <div className="group-name">{item.lesson_name}</div>
                          <div>
                            <ModalLessonInfo data={item} />
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
                <Stack spacing={2} alignItems="center" marginTop="20px">
                  <Pagination
                    count={totalPages}
                    page={page}
                    onChange={handlePageChange}
                    color="secondary"
                    variant="outlined"
                    shape="rounded"
                  />
                </Stack>
              </div>
            </div>
          </section>
        )}
      </main>
    </>
  );
}

export default MyGroups;

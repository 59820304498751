import React, { useEffect, useState } from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import { Add } from "@mui/icons-material";
import axios from "../../../lib/axios";
import TextField from "@mui/material/TextField";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: 500,
  width: "100%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  zIndex: 10,
};

export default function TechRewardModal(data) {
  const [open, setOpen] = useState(false);
  const [group, setGroup] = useState({});
  // const [title, setTitle] = useState("");
  const [miqdor, setMiqdor] = useState("");
  const [comment, setComment] = useState("");
  const Token = localStorage.getItem("accessToken");
  const [disabled, setDisabled] = useState(true);
  const config = {
    headers: {
      Authorization: Token,
    },
  };

  const createLesson = () => {
    // e.preventDefault();
    const formData = {
      // lesson_name: title,
      lesson_date: miqdor,
      description: comment,
      group_id: localStorage.getItem("group_id"),
    };

    axios
      .post("/lesson", formData, config)
      .then((response) => {
        console.log(response.data);
        setDisabled(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    createLesson();
    console.log(group, "ishladi");
    axios
      .get("/group/" + localStorage.getItem("group_id"), config)
      .then((response) => {
        console.log(response.data);
        setGroup(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [disabled]);

  const createReward = (e) => {
    e.preventDefault();
    const body = {
      award_percent: Number(miqdor),
      by_teacher: localStorage.getItem("guid"),
      description: comment,
      id: localStorage.getItem("group_id"),
      is_active: true,
      student_id: data.data.id,
      teacher_name: group.teacher_name,
    };
    miqdor && comment && axios
      .post("/award", body, config)
      .then((response) => {
        console.log(response.data);
        handleClose();
      })
      .catch((err) => {
        console.log(err.request.response);
      });
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
      <Button onClick={handleOpen}>
        <Add />
      </Button>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box component="form" sx={style} noValidate autoComplete="off">
            <form
              style={{
                display: "flex",
                flexDirection: "column",
                rowGap: "20px",
              }}
            >
              <h1 style={{ textAlign: "center" }}>Mukofot berish🏆</h1>
              <p className="flex">
                Talaba:{" "}
                <h3>
                  {data.data.first_name} {data.data.last_name}
                </h3>
              </p>
              <TextField
                required
                label="Miqdori"
                style={{ width: "100%" }}
                type="text"
                inputProps={{ max: 100 }}
                value={miqdor}
                placeholder="Foizlarda"
                onChange={(e) => {
                  const value = parseInt(e.target.value, 10);
                  if (value <= 100 && value >= 0) {
                    setMiqdor(e.target.value);
                  }
                }}
              />

              <TextField
                label="Izoh"
                required
                multiline
                rows={4}
                value={comment}
                placeholder="Izoh qoldirishingiz kerak"
                onChange={(e) => setComment(e.target.value)}
              />
              <div className="flex">
                <Button variant="contained" color="error" onClick={handleClose} >
                  Bekor qilish
                </Button>
                <Button
                  variant="contained"
                  id="createTask"
                  onClick={createReward}
                >
                  Taqdirlash
                </Button>
              </div>
            </form>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}

import React, { useEffect, useState } from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import { Edit } from "@mui/icons-material";
import axios from "../../../lib/axios";
import TextField from "@mui/material/TextField";
import moment from "moment";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: 500,
  width: "100%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  zIndex: 10,
};

export default function TechUpdateTest(item) {
  const [open, setOpen] = useState(false);
  const Token = localStorage.getItem("accessToken");
  const [disabled, setDisabled] = useState(true);
  const [id] = useState(item.id);
  const [testDate, setTestDate] = useState("");
  const [testInfo, setTestInfo] = useState("");
  const [maxScore, setMaxScore] = useState();

  const config = {
    headers: {
      Authorization: Token,
    },
  };

  console.log(item.data);

  const updateStudent = (e) => {
    e.preventDefault();
    const formData = {
      test_date: moment(testDate).format("MM/DD/YYYY"),
      id: item.data.id,
      test_info: testInfo,
      max_score: maxScore,
    };

    axios
      .put("/test", formData, config)
      .then(() => {
        window.location.reload();
      })
      .catch((err) => {
        console.log(err);
      });

    console.log(formData);
  };

  useEffect(() => {
    if (testDate && id && testInfo && maxScore) {
      setDisabled(false);
    }
  }, [disabled]);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
      <Button onClick={handleOpen}>
        <Edit />
      </Button>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box component="form" sx={style} noValidate autoComplete="off">
            <form
              style={{
                display: "flex",
                flexDirection: "column",
                rowGap: "20px",
              }}
            >
              <TextField
                required
                label="Test haqida"
                style={{ width: "100%" }}
                value={testInfo}
                onChange={(e) => setTestInfo(e.target.value)}
              />
              <TextField
                label="Max ball"
                style={{ width: "100%" }}
                value={maxScore}
                type="text"
                onChange={(e) => setMaxScore(Number(e.target.value))}
              />
              <TextField
                required
                label="Vaqti"
                focused
                style={{ width: "100%" }}
                type="date"
                value={testDate}
                onChange={(e) => setTestDate(e.target.value)}
              />
              <Button variant="contained" style={{ width: "100%" }} onClick={updateStudent}>
                Update Test
              </Button>
            </form>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}

import React, { useEffect, useState } from "react";
import "./Payments.scss";
import Header from "../../components/Header/Header";
import axios from "../../lib/axios";
import ModalPayments from "./ModalPayments";
import Pagination from "@mui/material/Pagination"; // Import Pagination
import Stack from "@mui/material/Stack"; // Impo

function Payments() {
  const [sDate, setSDate] = useState("");
  const [eDate, setEDate] = useState("");
  const [datas, setDatas] = useState("");
  const [page, setPage] = useState(1); // Current page state
  const [totalPages, setTotalPages] = useState(1); // Total pages state
  const [limit] = useState(10); // Number of items per page

  const STUDENT_ID = window.localStorage.getItem("guid");
  console.log(STUDENT_ID);
  const startDate = sDate.split("-");
  const endDate = eDate.split("-");
  const startDate2 =
    startDate[0] === "" ? "DD/MM/YYYY" : startDate[1] + "/" + startDate[2] + "/" + startDate[0];
  const endDate2 =
    endDate[0] === "" ? "DD/MM/YYYY" : endDate[1] + "/" + endDate[2] + "/" + endDate[0];

  const TOKEN = window.localStorage.getItem("accessToken");
  const config = {
    headers: {
      Authorization: `${TOKEN}`,
    },
  };

  useEffect(() => {
    axios
      .get(
        `payment/student-pays?s-date=${startDate2}&e-date=${endDate2}&sid=${STUDENT_ID}&page=${page}&limit=${limit}`,
        config,
      )
      .then((data) => {
        setDatas(data.data.payments);
        setTotalPages(data.data.total_count < limit ? 1 : Math.ceil(data.data.total_count / limit)); // Assuming your API returns the total number of pages
        console.log(data.data.payments);
      })
      .catch((e) => console.log(e));
  }, [eDate, STUDENT_ID, page]);

  const handlePageChange = (event, value) => {
    setPage(value); // Update the page state when user changes the page
  };

  return (
    <>
      <Header />
      <main>
        <section className="payments-section student-payment-section">
          <div className="container">
            <div className="payments">
              <div className="date-wrapper">
                <label htmlFor="">
                  Boshlanish sanasi
                  <input
                    className="input-date"
                    type="date"
                    value={sDate}
                    onChange={(e) => setSDate(e.target.value)}
                    />
                  </label>
                  <label htmlFor="">
                    Tugash sanasi
                <input
                  className="input-date"
                  type="date"
                  value={eDate}
                  onChange={(e) => setEDate(e.target.value)}
                  />
                  </label>
              </div>
              <table className="table desktop">
                <thead className="table-light">
                  <tr>
                    <th>#</th>
                    <th>Qiymat</th>
                    <th>Sana</th>
                    <th>Status</th>
                    <th>Guruh</th>
                  </tr>
                </thead>
                <tbody>
                  {datas && datas?.map((item, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{item.payment_amount} so`m</td>
                      <td>{item.payment_date.split("T")[0]}</td>
                      <td className="">
                        {item.payment_type === "card" ? "Karta" : "Naqd"}
                      </td>
                      <td className="">{item.group_name}</td>
                    </tr>
                  ))}
                </tbody>
              </table>

              <div className="mygroup__table mobile">
                <div className="mygroup__table-head">
                  <div className="">#</div>
                  <div className="">Sana</div>
                  <div></div>
                </div>
                <div className="mygroup__table-body">
                  {datas &&
                    datas.map((item, index) => (
                      <div key={index} className="mygroup__table-body--item">
                        <div style={{ padding: "23px" }} className="">{index + 1}</div>
                        <div style={{ padding: "23px", paddingLeft: "10px" }} className="group-name">
                          {item.payment_date.split("T")[0]}
                        </div>
                        <div>
                          <ModalPayments data={item} />
                        </div>
                      </div>
                    ))}
                </div>
              </div>
              <Stack spacing={2} alignItems="center" marginTop="20px">
                  <Pagination
                    count={totalPages}
                    page={page}
                    onChange={handlePageChange}
                    color="secondary"
                    variant="outlined"
                    shape="rounded"
                  />
                </Stack>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}

export default Payments;

import React, { useEffect, useState } from "react";
import "./TechAttendanceRating.scss";
import axios from "../../../lib/axios";
import TestNavbar from "../TechNavbar/TechNavbar";
import MyPopUp from "./MyPopUp";

function TechAttendanceRating() {
  const useAuth = window.localStorage;
  const Token = useAuth.getItem("accessToken");
  const [attendance, setAttendance] = useState([]);

  const config = {
    headers: {
      Authorization: Token,
    },
  };

  useEffect(() => {
    axios
      .get("/stats/g-attendance/" + localStorage.getItem("group_id"), config)
      .then((response) => {
        setAttendance(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  console.log(attendance, "attendance");

  return (
    <>
      <TestNavbar />
      <main className="mainPage">
        {attendance !== undefined ? (
          <section className="mygroup-section tech-lesson-section">
            <div className="container">
              <div className="mygroup tech-lesson">
                {attendance && attendance.length > 0 ? (
                <table className="mygroup__table desktop">
                  <thead>
                    <tr>
                      <th style={{ width: "50px" }} className="tablet">
                        #
                      </th>
                      <th className="tablet">Talabalar</th>
                      <th style={{ width: "250px" }}>
                        Qoldirilgan darslar soni
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {attendance.map((item, index) => (
                      <tr key={index} id="margin">
                        <td className="tablet">{index + 1}</td>
                        <td className="tablet">
                          <p>
                            {item.first_name} {item.last_name}
                          </p>
                        </td>
                        <td>
                          <p>{item.attendance_count}</p>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                ) : (
                  <h2>Ma`lumot mavjud emas!</h2>
                )}

                <div className="mygroup__table mobile">
                  <div className="mygroup__table-head">
                    <div className="">#</div>
                    <div>Talabalar</div>
                    <div className=""></div>
                  </div>
                  <div className="mygroup__table-body">
                    {attendance && attendance.map((item, index) => (
                      <div key={index} className="mygroup__table-body--item">
                        <div style={{ padding: "14px" }} className=""><p>{index + 1}</p></div>
                        <div style={{ padding: "14px" }} className="group-name">
                        <p>
                            {item.first_name} {item.last_name}
                          </p>
                        </div>
                        <div><MyPopUp data={item} /></div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </section>
        ) : (
          <section className="mygroup-section tech-lesson-section">
            <div className="container">
              <div className="mygroup">
                <h2>Ma`lumot mavjud emas!</h2>
              </div>
            </div>
          </section>
        )}
      </main>
    </>
  );
}

export default TechAttendanceRating;

import React, { useEffect, useState } from "react";
import "./Attendance.scss";
import axios from "../../lib/axios";
import ModalAttendance from "./ModalAttendance";

function Attendance() {
  const useAuth = window.localStorage;
  const studentId = useAuth.getItem("guid");
  const Token = useAuth.getItem("accessToken");
  const [data, setData] = useState([]);

  const config = {
    headers: {
      Authorization: Token,
    },
  };

  useEffect(() => {
    axios
      .get(`stats/s-attendance/${studentId}`, config)
      .then((response) => {
        setData(response.data);
        console.log(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  console.log(data, "data");
  return (
    <>
      {data && data.length > 0 ? (
        <section className="mygroup-section student-attendance-section">
          <div className="container">
            <div className="mygroup">
              <table className="mygroup__table desktop">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Guruh</th>
                    <th>Dars</th>
                    <th>Sana</th>
                  </tr>
                </thead>
                {(
                  <tbody>
                    {data.map((item, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{item.group_name}</td>
                        <td>{item.lesson_name}</td>
                        <td>{item.lesson_date.split("T")[0]}</td>
                      </tr>
                    ))}
                  </tbody>
                )}
              </table>

              <div className="mygroup__table mobile">
                <div className="mygroup__table-head">
                  <div className="">#</div>
                  <div className="">Darslar</div>
                  <div></div>
                </div>
                <div className="mygroup__table-body">
                  {
                    data.map((item, index) => (
                      <div key={index} className="mygroup__table-body--item">
                        <div style={{ padding: "23px" }} className="">{index + 1}</div>
                        <div style={{ padding: "23px" }} className="group-name">
                          {item.group_name} / {item.lesson_date.split("T")[0]}
                        </div>
                        <div>
                          <ModalAttendance data={item} />
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </section>
      ) : (
        <section className="mygroup-section">
          <div className="container">
            <h1
              className="no-data"
              style={{ textAlign: "center", color: "red" }}
            >
              Davomat mavjud emas
            </h1>
          </div>
        </section>
      )}
    </>
  );
}

export default Attendance;

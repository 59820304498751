import React, { useEffect, useState } from "react";
import "./TechAttendance.scss";
import axios from "../../../lib/axios";
import { NavLink } from "react-router-dom";
import TestNavbar from "../TechNavbar/TechNavbar";
import Button from "@mui/material/Button";
import { KeyboardReturn, Send } from "@mui/icons-material";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";

function TechAttendance() {
  const useAuth = window.localStorage;
  const userId = useAuth.getItem("guid");
  const Token = useAuth.getItem("accessToken");
  const [attendance, setAttendance] = useState([]);
  const [lessons, setLessons] = useState([]);
  const [students, setStudents] = useState();
  const [noAttendance, setNoAttendance] = useState(null);
  const [attendanceState, setAttendanceState] = useState({});
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [limit] = useState(10);

  const config = {
    headers: {
      Authorization: Token,
    },
  };

  useEffect(() => {
    const id = localStorage.getItem("group_id");
    if (id) {
      axios
        .get(
          `lesson/group-lesson?gid=${id}&page=${page}&limit=${limit}&sid=${userId}`,
          config
        )
        .then((response) => {
          setLessons(response.data.lessons);
          setTotalPages(
            response.data.total_count < limit ? 1 : Math.ceil(response.data.total_count / limit)
          );
        })
        .catch((err) => {
          console.log(err);
        });

      if (students !== undefined) {
        axios
          .get(`/groups/students/${students.group_id}`, config)
          .then((response) => {
            setAttendance(response.data.students);

            // Initialize attendance state
            const initialAttendanceState = response.data.students.reduce(
              (acc, student) => ({
                ...acc,
                [student.id]: false,
              }),
              {}
            );
            setAttendanceState(initialAttendanceState);
          })
          .catch((err) => {
            console.log(err);
          });
      }

      getAttendanceList();
    }
  }, [students, page]);

  const getAttendanceList = () => {
    axios
      .get(`attendance?lid=${students?.id}`, config)
      .then((response) => {
        setNoAttendance(response.data);
        if (response.data.attendance_students) {
          const updatedAttendanceState = response.data.attendance_students.reduce(
            (acc, attendanceRecord) => ({
              ...acc,
              [attendanceRecord.student_id]: attendanceRecord.is_here,
            }),
            {}
          );
          setAttendanceState(updatedAttendanceState);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleCheckboxChange = (studentId) => {
    setAttendanceState((prevState) => ({
      ...prevState,
      [studentId]: !prevState[studentId],
    }));
  };

  const confirmHomework = () => {
    const studentList = {
      attendance_students: Object.entries(attendanceState).map(
        ([studentId, isHere]) => ({
          student_id: studentId,
          is_here: isHere,
        })
      ),
      lesson_id: students.id,
    };

    axios
      .post("/attendance", studentList, config)
      .then((response) => {
        console.log(response.data);
      })
      .catch((err) => {
        console.log(err.response.data.message);
      });
    setStudents(undefined);
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const back = () => {
    setStudents(undefined);
  };

  return (
    <>
      <TestNavbar />
      <main className="mainPage">
        {students !== undefined ? (
          <section className="mygroup-section tech-lesson-section">
            <div className="container">
              <div className="mygroup tech-lesson">
                <table className="mygroup__table desktop">
                  <thead>
                    <tr>
                      <th className="tablet">#</th>
                      <th className="tablet">Talabalar</th>
                      <th>Davomat</th>
                    </tr>
                  </thead>
                  <tbody>
                    {attendance.map((item, index) => (
                      <tr key={index} id={item.id}>
                        <td className="tablet">{index + 1}</td>
                        <td className="tablet">
                          <p>{item.first_name + " " + item.last_name}</p>
                        </td>
                        <td>
                        {noAttendance === null ? (
                          <input
                            type="checkbox"
                            checked={attendanceState[item.id] || false}
                            onChange={() => handleCheckboxChange(item.id)}
                          />
                        ) : (
                          noAttendance.attendance_students.find(
                            (i) => i.student_id === item.id
                          )?.is_here ? "✔️" : "✖️"
                        )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>

                <div className="mygroup__table mobile tech-attendance-mobile">
                  <div className="mygroup__table-head">
                    <div className="">#</div>
                    <div className="">Talabalar</div>
                    <div>Davomat</div>
                  </div>
                  <div className="mygroup__table-body">
                    {attendance.map((item, index) => (
                      <div
                        key={index}
                        id={item.id}
                        className="mygroup__table-body--item"
                      >
                        <div className="">{index + 1}</div>
                        <div className="group-name">
                          {item.first_name + " " + item.last_name}
                        </div>
                        <div>
                        {noAttendance === null ? (
                          <input
                            type="checkbox"
                            checked={attendanceState[item.id] || false}
                            onChange={() => handleCheckboxChange(item.id)}
                          />
                        ) : (
                          noAttendance.attendance_students.find(
                            (i) => i.student_id === item.id
                          )?.is_here ? "✔️" : "✖️"
                        )}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>

                <div className="mygroup__btn">
                  <Button
                    variant="outlined"
                    onClick={back}
                    startIcon={<KeyboardReturn />}
                  >
                    Ortga qaytish
                  </Button>
                  <Button
                    variant="contained"
                    onClick={confirmHomework}
                    endIcon={<Send />}
                  >
                    Saqlash
                  </Button>
                </div>
              </div>
            </div>
          </section>
        ) : (
          <section className="mygroup-section tech-lesson-section">
            <div className="container">
              <div className="mygroup">
                <table className="mygroup__table desktop">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Dars nomi</th>
                      <th>Dars sana</th>
                    </tr>
                  </thead>
                  <tbody>
                    {lessons &&
                      lessons.map((item, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td id="lessonName">
                            <NavLink onClick={() => setStudents(item)}>
                              {item.lesson_name}
                            </NavLink>
                          </td>
                          <td id="lessonDate">
                            {item.lesson_date.split("T")[0]}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>

                <div className="mygroup__table mobile tech-attendance">
                  <div className="mygroup__table-head">
                    <div className="">#</div>
                    <div
                      className=""
                      style={{ justifyContent: "normal" }}
                    >
                      Dars nomi
                    </div>
                  </div>
                  <div className="mygroup__table-body">
                    {lessons &&
                      lessons.map((item, index) => (
                        <div
                          key={index}
                          className="mygroup__table-body--item"
                        >
                          <div className="">
                            <p style={{ padding: "15px" }}>{index + 1}</p>
                          </div>
                          <div
                            id="lessonName"
                            style={{ width: "100% !important" }}
                          >
                            <NavLink onClick={() => setStudents(item)}>
                              {item.lesson_name}
                            </NavLink>
                            <div
                              style={{
                                paddingTop: "10px",
                                color: "black",
                                fontSize: "12px",
                              }}
                            >
                              {item.lesson_date.split("T")[0]}
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
                <Stack spacing={2} alignItems="center" marginTop="20px">
                  <Pagination
                    count={totalPages}
                    page={page}
                    onChange={handlePageChange}
                    color="secondary"
                    variant="outlined"
                    shape="rounded"
                  />
                </Stack>
              </div>
            </div>
          </section>
        )}
      </main>
    </>
  );
}

export default TechAttendance;

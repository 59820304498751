import React, { useState } from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import { GroupAdd } from "@mui/icons-material";
import axios from "../../../lib/axios";
import TextField from "@mui/material/TextField";
import { useParams } from "react-router-dom";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: 820,
  width: "100%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  zIndex: 10,
};

export default function AdminAddAnotherStudentToTest() {
  const Token = localStorage.getItem("accessToken");
  const [open, setOpen] = useState(false);
  // const [testId, setTestId] = useState("");
  // const [disabled, setDisabled] = useState(true);
  const [studentId, setStudentId] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [age, setAge] = useState();
  const [educationCenter, setEducationCenter] = useState("");
  const [classNumber, setClassNumber] = useState();
  const [studentScore, setStudentScore] = useState();
  const [phoneNumber, setPhoneNumber] = useState("");
  const { id: testId } = useParams();

  const config = {
    headers: {
      Authorization: Token,
    },
  };

  const createStudent = (e) => {
    e.preventDefault();
    const formData = {
      test_id: testId,
      student_id: studentId,
      student_score: studentScore,
      phone_number: phoneNumber,
      last_name: lastName,
      first_name: firstName,
      age,
      education_center: educationCenter,
      class_number: classNumber,
    };

    axios
      .post("/test/another", formData, config)
      .then(() => {
        window.location.reload();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
      <Button onClick={handleOpen}>
        <GroupAdd />
      </Button>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box component="form" sx={style} noValidate autoComplete="off">
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                rowGap: "20px",
                columnGap: "20px",
                justifyContent: "center"
              }}
            >
            <TextField
              label="Student id"
              style={{ display: "none" }}
              variant="outlined"
              value={studentId}
              onChange={(e) => setStudentId(e.target.value)}
            />
            <TextField
              label="Ism"
              variant="outlined"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />
            <TextField
              label="Familiya"
              variant="outlined"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
            />
            <TextField
              label="Age"
              variant="outlined"
              type="text"
              value={age}
              onChange={(e) => setAge(Number(e.target.value))}
            />
            <TextField
              label="O`quv markaz"
              variant="outlined"
              value={educationCenter}
              onChange={(e) => setEducationCenter(e.target.value)}
            />
            <TextField
              label="Sinf"
              variant="outlined"
              value={classNumber}
              type="text"
              onChange={(e) => setClassNumber(Number(e.target.value))}
            />
            <TextField
              label="Ball"
              variant="outlined"
              type="text"
              value={studentScore}
              onChange={(e) => setStudentScore(Number(e.target.value))}
            />
            <TextField
              label="Telefon raqam"
              variant="outlined"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
            />
              <Button
                variant="contained"
                style={{ width: "100%" }}
                onClick={createStudent}
              >
                Qo`shish
              </Button>
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}

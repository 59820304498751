import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

const CircleDiagram = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 250px;
`;

const CircleTitle = styled.div`
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
`;

const Circle = styled.div`
  width: 150px;
  height: 150px;
  border-radius: 50%;
  background: #f3f3f3;
  position: relative;
  box-shadow: inset 0 0 20px rgba(0, 0, 0, 0.1);
`;

const Mask = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  border-radius: 50%;
  clip: rect(0px, 75px, 150px, 0px);
`;

const Fill = styled.div`
  background-color: #007bff;
  clip: rect(0px, 75px, 150px, 0px);
  width: 100%;
  height: 100%;
  border-radius: 50%;
  position: absolute;
  transform: ${({ rotation }) => `rotate(${rotation}deg)`};
`;

const InsideCircle = styled.div`
  width: 120px;
  height: 120px;
  border-radius: 50%;
  background: #ffffff;
  line-height: 120px;
  text-align: center;
  margin: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 24px;
  font-weight: bold;
`;

const CircularProgress = ({ percentage, title }) => {
  const rotation = (percentage / 100) * 360;

  return (
    <CircleDiagram>
      <CircleTitle>{title}</CircleTitle>
      <Circle>
        <Mask>
          <Fill rotation={rotation > 180 ? 180 : rotation} />
        </Mask>
        <Mask style={{ transform: "rotate(180deg)" }}>
          <Fill rotation={rotation > 180 ? rotation - 180 : 0} />
        </Mask>
        <InsideCircle>{percentage}%</InsideCircle>
      </Circle>
    </CircleDiagram>
  );
};

CircularProgress.propTypes = {
  percentage: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
};

export default CircularProgress;

import React, { useEffect, useState } from "react";
import axios from "../../../lib/axios";
import ViewStudent from "./AdminViewPayment";
import "./AdPayment.scss";

import TechAddLesson from "./AdminAddPayment";
import { Link, useNavigate } from "react-router-dom";
import AdNavbar from "../AdNavbar/AdNavbar";
import Pagination from "@mui/material/Pagination"; // Import Pagination
import Stack from "@mui/material/Stack"; // Impo

export default function AdPayment() {
  const useAuth = window.localStorage;
  const navigate = useNavigate();
  const Token = useAuth.getItem("accessToken");
  const [students, setStudents] = useState();
  const [change, setChange] = useState(false);
  const [page, setPage] = useState(1); // Current page state
  const [totalPages, setTotalPages] = useState(1); // Total pages state
  const [limit] = useState(10); // Number of items per page

  const config = {
    headers: {
      Authorization: Token,
    },
  };

  useEffect(() => {
    axios
      .get(`/payment/student-list?page=${page}&limit=${limit}`, config)
      .then((response) => {
        console.log(response.data);
        setStudents(response.data.students);
        setTotalPages(response.data.total_count < limit ? 1 : Math.ceil(response.data.total_count / limit)); // Assuming your API returns the total number of pages
      })
      .catch((err) => {
        if (err.response.status === 401) {
          navigate("/logout");
        }
        console.log(err);
      });
  }, [page]);

  console.log(students, "lesson");

  const handlePageChange = (event, value) => {
    setPage(value); // Update the page state when user changes the page
  };

  return (
    <>
      <AdNavbar />
      <main className="mainPage">
        <section className="mygroup-section tech-lesson-section">
            <div className="container">
              <div className="mygroup">
                <table className="mygroup__table desktop">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Talabalar</th>
                      <th>Login</th>
                      <th className="add-wrapper">
                        <TechAddLesson />
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {students &&
                      students.map((item, index) => (
                        <tr key={index} id={item.id}>
                          <td>{index + 1}</td>
                          <td id="lessonName">
                            <Link to={`/ad-payments/${item.student_id}`} onClick={() => setChange(!change)}>
                              {item.student_first_name + " " + item.student_last_name}
                            </Link>
                          </td>
                          <td>
                            <p>{item.student_login}</p>
                          </td>
                          <td className="add-wrapper">
                            <ViewStudent data={item}/>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>

                <div className="mygroup__table mobile">
                  <div className="mygroup__table-head">
                    <div className="">#</div>
                    <div className="">Talabalar</div>
                    <TechAddLesson />
                    {/* <div>Login</div> */}
                  </div>
                  <div className="mygroup__table-body">
                    {students &&
                      students.map((item, index) => (
                        <div key={index} className="mygroup__table-body--item">
                          <div style={{ padding: "14px" }} className=""><p>{index + 1}</p></div>
                          <div style={{ padding: "14px" }} className="group-name">
                          <p><Link to={`/ad-payments/${item.student_id}`} onClick={() => setChange(!change)}>
                              {item.student_first_name + " " + item.student_last_name}
                            </Link></p>
                          </div>
                          <div><ViewStudent data={item}/></div>
                        </div>
                      ))}
                  </div>
                </div>
                <Stack spacing={2} alignItems="center" marginTop="20px">
                  <Pagination
                    count={totalPages}
                    page={page}
                    onChange={handlePageChange}
                    color="secondary"
                    variant="outlined"
                    shape="rounded"
                  />
                </Stack>
              </div>
            </div>
          </section>
      </main>
    </>
  );
}

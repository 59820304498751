import React, { useEffect, useState } from "react";
import "./Appropriation.scss";
import axios from "../../lib/axios";
import ModalAppropriation from "./ModalAppropriation";

function Appropriation() {
  const useAuth = window.localStorage;
  const studentId = useAuth.getItem("guid");
  const Token = useAuth.getItem("accessToken");
  const [data, setData] = useState([]);

  const config = {
    headers: {
      Authorization: Token,
    },
  };

  useEffect(() => {
    axios
      .get(`stats/s-point/${studentId}`, config)
      .then((response) => {
        setData(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  console.log(data);
  return (
  <>
      <section className="mygroup-section">
        <div className="container">
          <div className="mygroup">
            <table className="mygroup__table desktop">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Guruh</th>
                  <th>Ball</th>
                  <th>O`rin</th>
                </tr>
              </thead>
              <tbody>
                {data && data.map((item, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{item.group_name}</td>
                    <td>{item.avg_point.toFixed(0)}</td>
                    <td>{item.rating}/{item.student_count}</td>
                  </tr>
                ))}
              </tbody>
            </table>

             <div className="mygroup__table mobile">
              <div className="mygroup__table-head">
                <div className="">#</div>
                <div className="">Guruh</div>
                <div></div>
              </div>
              <div className="mygroup__table-body">
                {data &&
                  data.map((item, index) => (
                    <div key={index} className="mygroup__table-body--item">
                      <div style={{ padding: "23px" }} className="">{index + 1}</div>
                      <div style={{ padding: "23px" }} className="group-name">
                        {item.group_name}
                      </div>
                      <div>
                        <ModalAppropriation data={item} />
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </section>
    {/* <Footer /> */}
  </>
  );
}

export default Appropriation;

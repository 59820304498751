import React, { useEffect, useState } from "react";
import "./Reward.scss";
import axios from "../../lib/axios";
import ModalReward from "./ModalReward";
import Pagination from "@mui/material/Pagination"; // Import Pagination
import Stack from "@mui/material/Stack"; // Impo

function Reward() {
  const useAuth = window.localStorage;
  const userId = useAuth.getItem("guid");
  const Token = useAuth.getItem("accessToken");
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1); // Current page state
  const [totalPages, setTotalPages] = useState(1); // Total pages state
  const [limit] = useState(10); // Number of items per page
  // console.log(userId);

  const config = {
    headers: {
      Authorization: Token,
    },
  };

  useEffect(() => {
    axios
      .get(`award/sid?sid=${userId}&page=${page}&limit=${limit}`, config)
      .then((response) => {
        setData(response.data.awards);
        setTotalPages(response.data.total_count < limit ? 1 : Math.ceil(response.data.total_count / limit)); // Assuming your API returns the total number of pages
      })
      .catch((err) => {
        console.log(err);
      });
  }, [page]);

  const handlePageChange = (event, value) => {
    setPage(value); // Update the page state when user changes the page
  };

  // console.log(data[0].is_active);
  return (
    <>
      <section className="mygroup-section">
        <div className="container">
          <div className="mygroup">
            <table className="mygroup__table  desktop">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Foiz</th>
                  <th>Holati</th>
                  <th>Ustoz</th>
                  <th>Malumot</th>
                </tr>
              </thead>
              {data.length > 0 && (
                <tbody>
                  {data.map((item, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{item.award_percent}%</td>
                      <td>{item.is_active === true ? "Faol" : "Tugagan"}</td>
                      <td>{item.teacher_name}</td>
                      <td>{item.description}</td>
                    </tr>
                  ))}
                </tbody>
              )}
            </table>

            <div className="mygroup__table mobile">
                <div className="mygroup__table-head">
                    <div className="">#</div>
                    <div className="">Foiz</div>
                    <div></div>
                </div>
                <div className="mygroup__table-body">
                  {data && data.map((item, index) => (
                    <div key={index} className="mygroup__table-body--item">
                      <div style={{ padding: "23px" }} className="">{index + 1}</div>
                      <div style={{ padding: "23px" }} className="group-name">
                        {item.award_percent}%
                      </div>
                      <div><ModalReward data={item}/></div>
                    </div>
                  ))}
                </div>
              </div>
              <Stack spacing={2} alignItems="center" marginTop="20px">
                  <Pagination
                    count={totalPages}
                    page={page}
                    onChange={handlePageChange}
                    color="secondary"
                    variant="outlined"
                    shape="rounded"
                  />
                </Stack>
          </div>
        </div>
      </section>
    </>
  );
}

export default Reward;

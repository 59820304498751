import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
// import MessageIcon from "@mui/icons-material/Message";
import { RemoveRedEye } from "@mui/icons-material";
// import axios from "../../../lib/axios";
import moment from "moment/moment";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  // border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

// const config = {
//   headers: {
//     Authorization: window.localStorage.getItem("accessToken"),
//   },
// };

export default function AdminViewPayment(data) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  console.log(data.data, "modal");

  // axios.get(`payment/student-pays?s-date=${"DD%2FMM%2FYYYY"}&e-date=DD%2FMM%2FYYYY&sid=${data.data}&page=1&limit=50`, config).then((data) => {
  //   console.log(data.data);
  // }).catch((err) => {
  //   console.log(err);
  // });

  return (
    <div>
      <Button onClick={handleOpen}><RemoveRedEye /></Button>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        { data.data && <Fade in={open}>
          <Box sx={style}>
            <Typography id="transition-modal-title" variant="h6" component="h2">
              To`liq ma`lumot:
            </Typography>
            { data.data && data.data.student_payments.slice(0, 4).map(
              (item, index) => (
                <Typography id="transition-modal-description" key={index} sx={{ mt: 2 }}>
                  {/* <strong>Talaba:</strong> {data.data.student_first_name} {data.data.student_last_name}<br />
                  <strong>Login:</strong> {data.data.student_login} {data.data.student_last_name}<br /> */}
                  <strong>Guruh nomi:</strong> {item.group.group_name}<br />
                  <strong>Minqdori:</strong> {item.payment_amount}<br />
                  <strong>To`lov qilingan sana:</strong> {moment(item.payment_date).format("DD/MM/YYYY")}<br />
                  <strong>To`lov turi:</strong> {item.payment_type === "cash" ? "Naqd" : "Plastik"}<br />
                </Typography>
              ))}
          </Box>
        </Fade>}
      </Modal>
    </div>
  );
}

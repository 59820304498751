import React from "react";
import "./Rating.scss";
import Header from "../../components/Header/Header";
import { NavLink, Outlet } from "react-router-dom";

function Rating() {
  return (
    <>
      <Header />
      <main className="mainPage">
        <section className="rating-section">
          <div className="container">
            <div className="rating">
              <div className="rating__wrapper">
                <NavLink to={""}>Davomat</NavLink>
                <NavLink to={"appropriation"}>O`zlashtirish</NavLink>
              </div>
              <Outlet/>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}

export default Rating;

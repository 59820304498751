import React, { useEffect, useState } from "react";
import "./ForgotPassword.scss";
import { NavLink, useNavigate } from "react-router-dom";
import axios from "../../../lib/axios";

function ForgotPassword() {
  const [login, setLogin] = useState("");
  const [submit, setSubmit] = useState(false);
  const [status, setStatus] = useState();
  const [userData, setUserData] = useState();
  // const [isBegin, setIsBegin] = useState(true);
  const [isCompleted, setIsCompleted] = useState(false);
  const [otp, setOtp] = useState("");
  const [email, setIsEmail] = useState("");
  const navigate = useNavigate();
  const [isSuccess, setIsSuccess] = useState(false);

  useEffect(() => {
    if (!isCompleted) {
      window.localStorage.setItem("login", login);
      axios
        .get(`/forgot/${login}`)
        .then((result) => {
          setOtp("");
          setStatus(result.status);
          setIsEmail(result.data.email);
          setIsSuccess(true);
          setUserData(result);
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }, [submit, login]);

  useEffect(() => {
    if (isCompleted && isSuccess) {
      axios
        .get(`verify?otp=${otp}&email=${email}`)
        .then((result) => {
          if (result.status === 200) {
            navigate("/password-update");
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }, [isCompleted, isSuccess, navigate, otp, email]);

  const handleOtpChange = (e, index) => {
    const value = e.target.value;
    if (/^[0-9]$/.test(value)) {
      const newOtp = otp.split("");
      newOtp[index] = value;
      setOtp(newOtp.join(""));
      if (e.target.nextSibling) {
        e.target.nextSibling.focus();
      }
    }
  };

  const handleOtpBackspace = (e, index) => {
    if (e.key === "Backspace") {
      const newOtp = otp.split("");
      newOtp[index] = "";
      setOtp(newOtp.join(""));
      if (e.target.previousSibling) {
        e.target.previousSibling.focus();
      }
    }
  };

  const end = () => {
    setSubmit(false);
    setLogin("");
    setStatus(0);
  };

  const verify = () => {
    setIsCompleted(true);
    setSubmit(true);
  };

  return (
    <>
      <section className="forgot-section">
        <div className="container">
          <div className="forgot">
            <div className="forgot-wrapper">
              <h2>
                {status === 200 ? `Hurmatli ${userData.data.first_name}! Parolingizni qayta tiklash uchun ushbu ${userData.data.email} elektron manzilga kelgan parolni kiriting!` : "Parolingiz esdan chiqdimi? Parolingizni qayta tiklash uchun loginingizni kiriting!"}
              </h2>
            </div>
            {status === 200 ? (
              <div className="forgot-square">
                {Array.from({ length: 6 }).map((_, index) => (
                  <input
                    key={index}
                    type="text"
                    maxLength={1}
                    onChange={(e) => handleOtpChange(e, index)}
                    onKeyDown={(e) => handleOtpBackspace(e, index)}
                    value={otp[index] || ""}
                    style={{ width: "50px", textAlign: "center", fontSize: "24px" }}
                  />
                ))}
              </div>
            ) : (
              <input
                type="text"
                placeholder="Enter your login"
                value={login}
                onChange={(evt) => setLogin(evt.target.value)}
                required
              />
            )}
            <div className="flex">
              {status === 200 ? (
                <>
                  <NavLink className="btn btn-login" onClick={end}>
                    Ortga qaytish
                  </NavLink>
                  <NavLink className="btn btn-login" onClick={verify}>
                    Tasdiqlash
                  </NavLink>
                </>
              ) : (
                <>
                  <NavLink className="btn btn-login" to={"/logout"}>
                    Ortga qaytish
                  </NavLink>
                  <NavLink
                    className="btn btn-login"
                    onClick={() => setSubmit((pre) => !pre)}
                  >
                    Davom etish
                  </NavLink>
                </>
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default ForgotPassword;

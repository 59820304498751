import React, { useEffect, useState } from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import { Edit } from "@mui/icons-material";
import axios from "../../../lib/axios";
import TextField from "@mui/material/TextField";
import useWindowDimensions from "../../../hooks/useWindowDimensions";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: 500,
  width: "100%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  zIndex: 10,
};

export default function TechUpdateLesson(item) {
  const [open, setOpen] = useState(false);
  const Token = localStorage.getItem("accessToken");
  const [disabled, setDisabled] = useState(true);
  const [id] = useState(item.id);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [age, setAge] = useState();
  const [classNumber, setClassNumber] = useState();
  const [email, setEmail] = useState("");
  const [gender, setGender] = useState("");
  const [login, setLogin] = useState();
  const { width } = useWindowDimensions();
  const [phoneNumber, setPhoneNumber] = useState("");

  const config = {
    headers: {
      Authorization: Token,
    },
  };

  console.log(item.data);

  const updateStudent = (e) => {
    e.preventDefault();
    const formData = {
      age,
      class_number: classNumber,
      email,
      first_name: firstName,
      gender,
      id: item.data.id,
      last_name: lastName,
      login,
      phone_number: phoneNumber,
    };

    axios
      .put("/student", formData, config)
      .then((response) => {
        console.log(response.data);
        window.location.reload();
      })
      .catch((err) => {
        console.log(err);
      });

    console.log(formData);
  };

  // const updateLesson = (e) => {
  //   e.preventDefault();
  //   const formData = {
  //     id: item.data.id,
  //     lesson_name: title,
  //     lesson_date: date,
  //     description: "",
  //     group_id: localStorage.getItem("group_id"),
  //   };

  //   console.log(formData);

  //   axios
  //     .put("/student", formData, config)
  //     .then((response) => {
  //       console.log(response.data);
  //       window.location.reload();
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };

  useEffect(() => {
    if (age && classNumber && email && firstName && gender && id && lastName && login && phoneNumber) {
      setDisabled(false);
    }
  }, [disabled]);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
      <Button onClick={handleOpen}>
        <Edit />
      </Button>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box component="form" sx={style} noValidate autoComplete="off">
            <form
              style={ width < 500 ? {
                display: "flex",
                flexDirection: "column",
                rowGap: "20px",
              } : {
                display: "flex",
                flexWrap: "wrap",
                rowGap: "20px",
                columnGap: "20px"
              }}
            >
              <TextField
                required
                label="Yosh"
                style={ width < 500 ? { width: "100%" } : { width: "200px" }}
                type="text"
                value={age}
                onChange={(e) => setAge(Number(e.target.value))}
              />
              <TextField
                required
                label="Sinf raqami"
                style={ width < 500 ? { width: "100%" } : { width: "200px" }}
                type="text"
                value={classNumber}
                onChange={(e) => setClassNumber(Number(e.target.value))}
              />
              <TextField
                required
                label="Elektron pochta"
                style={ width < 500 ? { width: "100%" } : { width: "200px" }}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <TextField
                required
                label="Ism"
                style={ width < 500 ? { width: "100%" } : { width: "200px" }}
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
              />
              <TextField
                required
                style={ width < 500 ? { width: "100%" } : { width: "200px" }}
                value={gender}
                onChange={(e) => {
                  setGender(e.target.value);
                }}
                select
                SelectProps={{
                  native: true,
                }}
              >
                <option hidden>Jinsni tanlang</option>
                <option value="male">Erkak</option>
                <option value="female">Ayol</option>
              </TextField>
              <TextField
                required
                label="Familiya"
                style={ width < 500 ? { width: "100%" } : { width: "200px" }}
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
              <TextField
                label="Login"
                style={ width < 500 ? { width: "100%" } : { width: "200px" }}
                value={login}
                type="text"
                onChange={(e) => setLogin(e.target.value)}
              />
              <TextField
                required
                label="Telefon raqami"
                style={ width < 500 ? { width: "100%" } : { width: "200px" }}
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
              />
              { width < 500 ? <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Button variant="contained" style={{ width: "100%" }} onClick={updateStudent}>
                Yangilash
              </Button>
              <Button variant="outlined" style={{ width: "100%" }} onClick={handleClose}>Ortga</Button>
              </div> : <Button variant="contained" style={{ width: "100%" }} onClick={updateStudent}>
                Yangilash
              </Button>}
            </form>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}

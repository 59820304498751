import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { RemoveRedEye } from "@mui/icons-material";
// import axios from "../../../lib/axios";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: 400,
  width: "100%",
  bgcolor: "background.paper",
  // border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function AdminViewGroup(data) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
      <Button onClick={handleOpen}><RemoveRedEye /></Button>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        { data.data && <Fade in={open}>
          <Box sx={style}>
            <Typography id="transition-modal-title" variant="h6" component="h2">
              To`liq ma`lumot:
            </Typography>
            <Typography id="transition-modal-description" sx={{ mt: 2 }}>
              <strong>Guruh:</strong> {data.data.group_name ? data.data.group_name : "Null"}<br />
              <strong>Ustoz:</strong> {data.data.teacher_name ? data.data.teacher_name : "Null"}<br />
              <strong>Yo`nalishi:</strong> {data.data.tag_name}<br />
              <strong>Boshlangan sanasi:</strong> {data.data.start_date.split("T")[0]}<br />
              <strong>Tugagan sanasi:</strong> {data.data.end_date ? data.data.end_date.split("T")[0] : "Noma'lum"}<br />
            </Typography>
          </Box>
        </Fade>}
      </Modal>
    </div>
  );
}

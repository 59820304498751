import React from "react";
import ReactDOM from "react-dom/client";
// import { createRoot } from "react-dom/client";
// import { provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
// import { store } from "./redux/store";
import "./index.css";
import App from "./app/App";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <Provider store={store}>
  <BrowserRouter>
    <App />
  </BrowserRouter>,
  // </Provider>
);

import React, { useEffect, useState } from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import { Add } from "@mui/icons-material";
import axios from "../../../lib/axios";
import TextField from "@mui/material/TextField";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: 500,
  width: "100%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  zIndex: 10,
};

export default function TechAddModal() {
  const [open, setOpen] = useState(false);
  const [lessonId, setLessonId] = useState("");
  const [title, setTitle] = useState("");
  const [date, setDate] = useState("");
  const [descriptions, setDescription] = useState("");
  const Token = localStorage.getItem("accessToken");
  const [disabled, setDisabled] = useState(true);
  const config = {
    headers: {
      Authorization: Token,
    },
  };

  const createLesson = (e) => {
    e.preventDefault();
    const formData = {
      lesson_name: title,
      lesson_date: date,
      description: descriptions,
      group_id: localStorage.getItem("group_id"),
    };

    axios
      .post("/lesson", formData, config)
      .then((response) => {
        console.log(response.data);
        setLessonId(response.data.id);
        setDisabled(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (title && date && descriptions) {
      setDisabled(false);
    }
  }, [disabled]);

  const createTask = (e) => {
    // e.preventDefault();
    const formData = {
      lesson_id: lessonId,
      description: descriptions,
    };
    axios
      .post("/homework", formData, config)
      .then((response) => {
        console.log(response.data);
        handleClose();
        window.location.reload();
        // window.location.reload();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
      <Button onClick={handleOpen}>
        <Add />
      </Button>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box component="form" sx={style} noValidate autoComplete="off">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                rowGap: "20px",
              }}
            >
              <TextField
                required
                label="Dars Nomi"
                style={{ width: "100%" }}
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
              <TextField
                required
                // label="Dars sanasi"
                style={{ width: "100%" }}
                type="date"
                value={date}
                onChange={(e) => {
                  setDate(e.target.value);
                }}
              />
              <Button variant="contained" onClick={createLesson}>
                Dars yaratish
              </Button>
              <TextField
                label="Vazifa"
                required
                multiline
                rows={4}
                value={descriptions}
                onChange={(e) => setDescription(e.target.value)}
              />
              <Button variant="contained" id="createTask" onClick={createTask}>
                Vazifa yaratish
              </Button>
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}

import React, { useEffect, useState } from "react";
import "./Profile.scss";
import Header from "../../components/Header/Header";
import men from "../../assets/logo/men.png";
import women from "../../assets/logo/women.png";
import axios from "../../lib/axios";

function Profile() {
  const [response, setResponse] = useState([]);

  const TOKEN = window.localStorage.getItem("accessToken");
  const USER_ID = window.localStorage.getItem("guid");
  const LOGIN = window.localStorage.getItem("login");
  const role = window.localStorage.getItem("role");

  const config = {
    headers: {
      Authorization: `${TOKEN}`,
    },
  };

  useEffect(() => {
    axios
      .get(
        role === "teacher" ? `https://erp.xasannosirov.uz/v1/teacher/${USER_ID}` : `https://erp.xasannosirov.uz/v1/student/${USER_ID}`,
        config,
      )
      .then((data) => {
        setResponse(data);
        localStorage.setItem("user_name", data.data.first_name + " " + data.data.last_name);
      })
      .catch((e) => console.log(e));
  }, []);

  return (
    <>
      <Header />
      <main>
        <section className="profile-section">
          <div className="container">
            {response.data !== undefined && (
              <div className="profile">
                <div className="flex info">
                  <img src={response.data.gender === "female" ? women : men} />
                  <div className="userInfo">
                    <h2 className="title">Shaxsiy ma`lumotlar</h2>
                    <div className="subinfo">
                      <div className="info-wrapper flex">
                        <strong>Ism</strong>
                        <p>{response.data.first_name}</p>
                      </div>
                      <div className="info-wrapper flex">
                        <strong>Familiya</strong>
                        <p>{response.data.last_name}</p>
                      </div>
                      <div className="info-wrapper flex">
                        <strong>Yosh:</strong>
                        <p>{response.data.age}</p>
                      </div>
                      <div className="info-wrapper flex">
                        <strong>
                          {role === "teacher" ? "Tajriba" : "Sinf"}
                        </strong>
                        <p>
                          {role === "teacher" ? response.data.experience : response.data.class_number}
                        </p>
                      </div>
                      <div className="info-wrapper flex">
                        <strong>Login</strong>
                        <p>{LOGIN}</p>
                      </div>
                    </div>
                  </div>
                  {role === "teacher" ? (
                    <div className="profile-wrapper">
                      <div className="contactInfo">
                        <h3 className="title">Kontakt ma`lumotlari</h3>
                        <div className="subinfo">
                          <div>
                            <strong>Telefon raqam</strong>
                            <p>{response.data.phone_number}</p>
                          </div>
                          <div>
                            <strong>Email pochta manzili</strong>
                            <p>{response.data.email}</p>
                          </div>
                        </div>
                      </div>
                      <div className="contactInfo">
                        <h3 className="title">Ma`lumot</h3>
                        <div className="subinfo">
                          <p>{response.data.bio ? response.data.bio : "Ma`lumot yo`q"}</p>
                        </div>
                      </div>
                    </div>
                  ) : role === "student" ? (
                    <div className="contactInfo">
                      <h2 className="title">Kontakt ma`lumotlari</h2>
                      <div className="subinfo">
                        <strong>Telefon raqam</strong>
                        <p>{response.data.phone_number}</p>
                        <strong>Email pochta manzili</strong>
                        <p>{response.data.email}</p>
                      </div>
                    </div>
                  ) : (
                    <div className="contactInfo">
                      <h2 className="title">Kontakt ma`lumotlari</h2>
                      <div className="subinfo">
                        <strong>Telefon raqam</strong>
                        <p>{response.data.phone_number}</p>
                        <strong>Email pochta manzili</strong>
                        <p>{response.data.email}</p>
                      </div>
                    </div>
                  )}
                </div>
                <div className="auth flex">
                </div>
              </div>
            )}
          </div>
        </section>
      </main>
    </>
  );
}

export default Profile;

import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
// import MessageIcon from "@mui/icons-material/Message";
import { RemoveRedEye } from "@mui/icons-material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: 400,
  width: "100%",
  bgcolor: "background.paper",
  // border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function AdminViewTeacher(data) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  // console.log(data.data, "modal");

  return (
    <div>
      <Button onClick={handleOpen}><RemoveRedEye /></Button>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        { data.data && <Fade in={open}>
          <Box sx={style}>
            <Typography id="transition-modal-title" variant="h6" component="h2">
              To`liq ma`lumot:
            </Typography>
            <Typography id="transition-modal-description" sx={{ mt: 2 }}>
              <strong>Ism:</strong> {data.data.first_name}<br />
              <strong>Familiya:</strong> {data.data.last_name}<br />
              <strong>Yosh:</strong> {data.data.age}<br />
              <strong>Telefon raqam:</strong> {data.data.phone_number}<br />
              <strong>Haqida:</strong> {data.data.bio}<br />
              <strong>Tajriba:</strong> {data.data.experience}<br />
              <strong>Email:</strong> {data.data.email}<br />
              {/* <strong>Id:</strong> {data.data.id}<br /> */}
              <strong>Login:</strong> {data.data.login}<br />
            </Typography>
          </Box>
        </Fade>}
      </Modal>
    </div>
  );
}

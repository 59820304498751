import React, { useEffect, useState } from "react";
import "./TechHomework.scss";
import axios from "../../../lib/axios";
import { useNavigate, useParams } from "react-router-dom";
import TestNavbar from "../TechNavbar/TechNavbar";
import Button from "@mui/material/Button";
import { KeyboardReturn, Send } from "@mui/icons-material";
import Alert from "@mui/material/Alert";
import useWindowDimensions from "../../../hooks/useWindowDimensions";

function TechHomeworkInner() {
  const useAuth = window.localStorage;
  const userId = useAuth.getItem("guid");
  const Token = useAuth.getItem("accessToken");
  const [attendance, setAttendance] = useState(undefined);
  const [lesson, setLesson] = useState();
  const [value, setValue] = useState("");
  // const [homeworkId, setHomeworkId] = useState("");
  const [points, setPoints] = useState(null);
  const { lessonId } = useParams();
  const navigate = useNavigate();
  const { groupId } = useParams();
  const { width } = useWindowDimensions();
  const [noPoint, setNoPoint] = useState();

  const config = {
    headers: {
      Authorization: Token,
    },
  };

  useEffect(() => {
    console.log(lessonId);
    axios
      .get("/homework/lesson/" + lessonId, config)
      .then((response) => {
        setAttendance();
        // console.log(attendance);
        setLesson(response.data);
        // console.log(response.data);
        // setPoints();
        localStorage.setItem("homeworkId", response.data.id);
      })
      .catch((err) => {
        console.log(err);
      });

    {
      const id = localStorage.getItem("group_id");
      id &&
      axios
        .get(
          `lesson/group-lesson?gid=${id}&page=1&limit=50&sid=${userId}`,
          config,
        )
        .then((response) => {
          // setLessons(response.data.lessons);
        })
        .catch((err) => {
          console.log(err);
        });
    }

    axios
      .get(`/lesson-points?gid=${groupId}&lid=${lessonId}`, config)
      .then((response) => {
        console.log(response.data);
        setPoints(response.data);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(`/groups/students/${groupId}`, config)
      .then((response) => {
        console.log(response.data.students);
        setAttendance(response.data.students);
        console.log(attendance, "attendance");
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const back = () => {
    navigate(`/${groupId}/tech-homework`);
  };

  const confirmHomework = (e) => {
    // if (width > 500) {
    let listCount = 0;
    for (
      let i = 0;
      i < e.target.parentNode.previousSibling.childNodes[1].childNodes.length;
      i++
    ) {
      const studentId =
        width < 500 ? e.target.parentNode.previousSibling.previousSibling.childNodes[1].childNodes[i].id : e.target.parentNode.previousSibling.childNodes[1].childNodes[i].id;
      console.log(studentId);
      const input =
        width < 500 ? e.target.parentNode.previousSibling.childNodes[1].childNodes[i].childNodes[2].childNodes[0] : e.target.parentNode.previousSibling.previousSibling.childNodes[1].childNodes[i].childNodes[2].childNodes[0];
      console.dir(input.value);

      const data = {
        homework_id: lesson.id,
        student_id: studentId,
        student_point: Number(input.value) === 0 ? null : Number(input.value),
      };
      console.log(data, input.value);
      if (input.value === "") {
        setNoPoint(true);
      } else {
        console.log(data, input.value);

        // axios
        //   .post("/point", data, config)
        //   .then((response) => {})
        //   .catch((err) => {
        //     console.log(err.response.data.message);
        //   });
      }
      listCount++;
    }
    if (e.target.parentNode.previousSibling.childNodes[1].childNodes.length !== listCount) {
      window.alert("Baholanmadi");
    } else if (noPoint) {
      window.alert("Baholanmadi");
    }
    // back();
    // } else {
    //   console.log(e.target.parentNode.childNodes);
    //   let listCount = 0;
    //   for (
    //     let i = 0;
    //     i < e.target.parentNode.previousSibling.childNodes[1].childNodes.length;
    //     i++
    //   ) {
    //     const studentId =
    //       e.target.parentNode.previousSibling.childNodes[1].childNodes[i].id;
    //     // console.log(e.target.parentNode.previousSibling.childNodes);
    //     const input =
    //       e.target.parentNode.previousSibling.childNodes[1].childNodes[i]
    //         .childNodes[2].childNodes[0];
    //     // console.log(lesson, "2222222222222222222222");
    //     const data = {
    //       homework_id: lesson.id,
    //       student_id: studentId,
    //       student_point: Number(input.value) === 0 ? null : Number(input.value),
    //     };
    //     console.log(data, input.value);
    //     if (input.value === "") {
    //       //
    //     } else {
    //       console.log(data, input.value);
    //       axios
    //         .post("/point", data, config)
    //         .then((response) => {})
    //         .catch((err) => {
    //           console.log(err.response.data.message);
    //         });
    //     }
    //     listCount++;
    //   }
    //   if (e.target.parentNode.previousSibling.childNodes[1].childNodes.length !== listCount) {
    //     window.alert("Baholanmadi");
    //   }
    //   // back();
    // }
  };

  const point = (item) => {
    return item.point;
  };

  return (
    <>
      <TestNavbar />
      <main className="mainPage">
        {
          <section className="mygroup-section tech-lesson-section">
            <div className="container">
              <div className="mygroup tech-lesson">
                <Alert
                  variant="outlined"
                  severity="info"
                  style={{ marginBottom: "20px" }}
                >
                  {lesson && lesson.description}
                </Alert>
                <table className="mygroup__table desktop">
                  <thead>
                    <tr>
                      <th className="tablet">#</th>
                      <th className="tablet">Talabalar</th>
                      <th>Baholash</th>
                    </tr>
                  </thead>
                  <tbody>
                    {attendance && attendance.map((item, index) => (
                      <tr key={index} id={item.id}>
                        <td className="tablet">{index + 1}</td>
                        <td className="tablet">
                          <p>{item.first_name + " " + item.last_name}</p>
                        </td>
                        <td
                          style={{
                            width: "200px",
                            padding: "0 80px 0px 25px",
                            fontSize: "20px",
                          }}
                        >
                          {!points ? (
                            <input
                              type="text"
                              minLength={1}
                              maxLength={3}
                              max={value.toString()}
                              style={{
                                width: "100%",
                                height: "100%",
                                outline: "none",
                              }}
                              onChange={(e) => setValue(e.target.value)}
                            />
                          ) : (
                            <p>
                              {
                                point(
                                  points.find((i) => i.student_id === item.id),
                                )
                              }
                            </p>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>

                <div className="mygroup__table mobile">
                  <div className="mygroup__table-head">
                    <div className="">#</div>
                    <div className="">Talabalar</div>
                    <div>Baholash</div>
                  </div>
                  <div className="mygroup__table-body">
                    {attendance && attendance.map((item, index) => (
                      <div key={index} className="mygroup__table-body--item" id={item.id}>
                        <div className=""><p>{index + 1}</p></div>
                        <div className="group-name">
                        <p>{item.first_name + " " + item.last_name}</p>
                        </div>
                        <div style={{ padding: "18px 0" }}>
                          {!points ? (
                            <input
                              type="text"
                              minLength={1}
                              maxLength={3}
                              max={value.toString()}
                              style={{
                                width: "100%",
                                height: "100%",
                                outline: "none",
                              }}
                              onChange={(e) => setValue(e.target.value)}
                            />
                          ) : (
                            <p>
                              {
                                point(
                                  points.find((i) => i.student_id === item.id),
                                )
                              }
                            </p>
                          )}
                          </div>
                      </div>
                    ))}
                  </div>
                </div>

                <div className="mygroup__btn">
                  <Button
                    variant="outlined"
                    onClick={back}
                    startIcon={<KeyboardReturn />}
                  >
                    Ortga qaytish
                  </Button>
                  <Button
                    variant="contained"
                    onClick={(e) => confirmHomework(e)}
                    endIcon={<Send />}
                  >
                    Saqlash
                  </Button>
                </div>
              </div>
            </div>
          </section>}
      </main>
    </>
  );
}

export default TechHomeworkInner;

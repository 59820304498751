import React, { useEffect, useState } from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import { Add } from "@mui/icons-material";
import axios from "../../../lib/axios";
import TextField from "@mui/material/TextField";
import moment from "moment";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: 500,
  width: "100%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  zIndex: 10,
};

export default function AdminAddGroup() {
  const Token = localStorage.getItem("accessToken");
  const [open, setOpen] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [_groupName, setGroupName] = useState("");
  const [_teacherName, setTeacherName] = useState("");
  const [_tagName, setTagName] = useState("");
  const [_startDate, setStartDate] = useState("");
  const [_endDate, setEndDate] = useState("");
  const [_startTime, setStartTime] = useState("");
  const [_endTime, setEndTime] = useState("");
  const [_id, setId] = useState("");
  const [_teacherId, setTeacherId] = useState();
  const [_teacher, setTeacher] = useState();

  const config = {
    headers: {
      Authorization: Token,
    },
  };

  useEffect(() => {
    axios.get("/teacher/select", config)
      .then((response) => {
        setTeacher(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const createStudent = (e) => {
    e.preventDefault();
    const formData = {
      group_name: _groupName,
      teacher_name: _teacherName,
      teacher_id: _teacherId,
      tag_name: _tagName,
      start_date: moment(_startDate).format("MM/DD/YYYY"),
      end_date: !_endDate ? null : moment(_endDate).format("MM/DD/YYYY"),
      id: _id,
      start_time: _startTime,
      end_time: _endTime,
    };

    console.log(formData);

    axios
      .post("/group", formData, config)
      .then((response) => {
        console.log(response.data);
        setDisabled(false);
        window.location.reload();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (
      _groupName &&
      _teacherName &&
      _teacherId &&
      _tagName &&
      _startDate &&
      _endDate &&
      _id &&
      _startTime &&
      _groupName &&
      _endTime
    ) {
      setDisabled(false);
    }
  }, [disabled]);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
      <Button onClick={handleOpen}>
        <Add />
      </Button>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box component="form" sx={style} noValidate autoComplete="off">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                rowGap: "20px",
                columnGap: "20px",
              }}
            >
              <TextField
                required
                label="Guruh nomi"
                style={{ width: "100%" }}
                value={_groupName}
                onChange={(e) => setGroupName(e.target.value)}
              />
              <TextField
                required
                style={{ width: "100%" }}
                value={_teacherName}
                onChange={(e) => {
                  setTeacherId(e.target.value.split("|")[0]);
                  setTeacherName(e.target.value.split("|")[1]);
                }}
                select
                SelectProps={{
                  native: true,
                }}
              >
                <option hidden>O`qituvchi tanlang</option>
                {_teacher && _teacher.map((item) => (
                  <option key={item.teacher_id} value={item.teacher_id}>
                    {item.teacher_name}
                  </option>
                ))}
              </TextField>
              {/* <TextField
                required
                label="O`qituvchi ismi"
                style={{ width: "100%" }}
                value={_teacherName}
                onChange={(e) => setTeacherName(e.target.value)}
              /> */}
              <TextField
                required
                hidden
                label="Teacher Id"
                style={{ width: "100%", display: "none" }}
                value={_teacherId}
                onChange={(e) => setTeacherId(Number(e.target.value))}
              />
              <TextField
                required
                label="Yo'nalish"
                style={{ width: "100%" }}
                value={_tagName}
                onChange={(e) => setTagName(e.target.value)}
              />
              <TextField
                required
                label="Boshlangan  sanasi"
                type="date"
                style={{ width: "100%" }}
                focused
                value={_startDate}
                onChange={(e) => setStartDate(e.target.value)}
              />
              <TextField
                required
                label="Tugagan sanasi"
                type="date"
                style={{ width: "100%" }}
                focused
                value={_endDate}
                onChange={(e) => setEndDate(e.target.value)}
              />
              <TextField
              hidden
                // required
                label="ID"
                style={{ width: "100%", display: "none" }}
                value={_id}
                onChange={(e) => setId(e.target.value)}
              />
              <TextField
                required
                label="Boshlangan vaqti"
                type="time"
                style={{ width: "100%" }}
                value={_startTime}
                focused
                onChange={(e) => setStartTime(e.target.value)}
              />
              <TextField
                required
                label="Tugagan vaqti"
                focused
                type="time"
                style={{ width: "100%" }}
                value={_endTime}
                onChange={(e) => setEndTime(e.target.value)}
              />
              <Button variant="contained" style={{ width: "100%" }} onClick={createStudent}>
                Guruh yaratish
              </Button>
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}

import React, { useEffect, useState } from "react";
import "./TechHomeworkRating.scss";
import axios from "../../../lib/axios";
// import MyModel from "../../../components/UI/MyModel/MyModel";
import TestNavbar from "../TechNavbar/TechNavbar";
// import Switch from "@mui/material/Switch";
// import Button from "@mui/material/Button";
// import { Delete, Send } from "@mui/icons-material";

// import { Button } from "bootstrap";
// import { Delete, Done, Edit } from "@mui/icons-material";
// import TechAddLesson from "./TechAddLesson";

function TechHomeworkRating() {
  // localStorage.removeItem("group_name");
  const useAuth = window.localStorage;
  // const userId = useAuth.getItem("guid");
  const Token = useAuth.getItem("accessToken");
  const [homework, setHomework] = useState([]);
  // const [lesson, setLesson] = useState();
  // const [change, setChange] = useState(false);
  // const [index, setIndex] = useState();
  // const [checked, setChecked] = useState(false);

  const config = {
    headers: {
      Authorization: Token,
    },
  };

  useEffect(() => {
    axios
      .get("/stats/g-point/" + localStorage.getItem("group_id"), config)
      .then((response) => {
        setHomework(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  // const handleChange = (event) => {
  //   setChecked(event.target.checked);
  // };

  // function handleClick(index) {
  //   localStorage.setItem("group_id", attendance[index].id);
  //   setIndex(index);
  // }

  console.log(homework, "homework");

  return (
    <>
      <TestNavbar />
      <main className="mainPage">
        {homework !== null ? (
          <section className="mygroup-section tech-lesson-section">
            <div className="container">
              <div className="mygroup tech-lesson">
                <table className="mygroup__table desktop">
                  <thead>
                    <tr>
                      <th style={{ width: "50px" }} className="tablet">
                        #
                      </th>
                      <th className="tablet">Talabalar</th>
                      <th style={{ width: "250px" }}>O`rtacha ball</th>
                    </tr>
                  </thead>
                  <tbody>
                    {homework && homework.map((item, index) => (
                      <tr key={index} id="margin">
                        <td className="tablet">{index + 1}</td>
                        <td className="tablet">
                          <p>
                            {item.first_name} {item.last_name}
                          </p>
                        </td>
                        <td>
                          <p>{item.avg_point.toFixed(0)}</p>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>

                <div className="mygroup__table mobile tech-homework-rating">
                  <div className="mygroup__table-head">
                    <div className="">#</div>
                    <div className="">Talaba ismi</div>
                    <div className="" style={{ width: "200px" }}>
                      Avg Ball
                    </div>
                  </div>
                  <div className="mygroup__table-body">
                    {homework && homework.map((item, index) => (
                      <div key={index} className="mygroup__table-body--item">
                        <div style={{ padding: "12px" }} className=""><p>{index + 1}</p></div>
                        <div style={{ padding: "12px" }} className="group-name">
                          <p>
                            {item.first_name} {item.last_name}
                          </p>
                        </div>
                        <div style={{ borderLeft: "0 !important" }} className="avg-point">
                          <p>{item.avg_point.toFixed(0)}</p>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </section>
        ) : (
          <section className="mygroup-section tech-lesson-section">
            <div className="container">
              <div className="mygroup">
                <h2>Ma`lumot mavjud emas!</h2>
              </div>
            </div>
          </section>
        )}
      </main>
    </>
  );
}

export default TechHomeworkRating;

import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import MessageIcon from "@mui/icons-material/Message";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: 400,
  width: "100%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 2,
};

export default function MyPopUp(data) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  console.log(data.data);

  return (
    <div>
      <Button onClick={handleOpen}><MessageIcon /></Button>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
          <Typography id="transition-modal-title" variant="h5" component="h2">
              Talabalar
            </Typography>
            <Typography id="transition-modal-description" sx={{ mt: 1, mb: 1 }}>
              {
                data.data ? <>{data.data.first_name} {data.data.last_name}</> : "Ma'lumot topilmadi"
              }
            </Typography>
            <Typography id="transition-modal-title" variant="h5" component="h2">
              Qoldirilgan darslar soni
            </Typography>
            <Typography id="transition-modal-description" sx={{ mt: 1, mb: 1 }}>
              {
                data.data ? data.data.attendance_count : "0"
              }
            </Typography>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}

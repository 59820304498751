import React, { useEffect, useState } from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import { Add } from "@mui/icons-material";
import axios from "../../../lib/axios";
import TextField from "@mui/material/TextField";
import "./AdStudent.scss";
import useWindowDimensions from "../../../hooks/useWindowDimensions";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: 1000,
  width: "100%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  zIndex: 10,
};

export default function TechAddModal() {
  const Token = localStorage.getItem("accessToken");
  const [open, setOpen] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [_password, setPassword] = useState("");
  const [_age, setAge] = useState();
  const [_classNumber, setClassNumber] = useState();
  const [_email, setEmail] = useState("");
  const [_firstName, setFirstName] = useState("");
  const [_gender, setGender] = useState("");
  const [_lastName, setLastName] = useState("");
  const [_phoneNumber, setPhoneNumber] = useState("");
  const { width } = useWindowDimensions();

  const config = {
    headers: {
      Authorization: Token,
    },
  };

  const createStudent = (e) => {
    e.preventDefault();
    const formData = {
      password: _password,
      student: {
        age: _age,
        class_number: _classNumber,
        email: _email,
        first_name: _firstName,
        gender: _gender,
        last_name: _lastName,
        phone_number: "+998" + _phoneNumber,
      }
    };

    axios
      .post("/student", formData, config)
      .then((response) => {
        console.log(response.data);
        setDisabled(false);
        window.location.reload();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (
      _password &&
      _age &&
      _classNumber &&
      _email &&
      _firstName &&
      _gender &&
      _lastName &&
      _phoneNumber
    ) {
      setDisabled(false);
    }
  }, [disabled]);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
      <Button onClick={handleOpen}>
        <Add />
      </Button>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box component="form" sx={style} noValidate autoComplete="off">
            <form
              onSubmit={createStudent}
              style={ width < 500 ? {
                display: "flex",
                flexDirection: "column",
                rowGap: "20px",
              } : {
                display: "flex",
                flexWrap: "wrap",
                rowGap: "20px",
                columnGap: "20px"
              }}
            >
              <TextField
                required
                label="Parol"
                style={ width < 500 ? { width: "100%" } : { width: "200px" }}
                type="text"
                value={_password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <TextField
                required
                label="Yosh"
                style={ width < 500 ? { width: "100%" } : { width: "200px" }}
                type="text"
                value={_age}
                onChange={(e) => setAge(Number(e.target.value) ? Number(e.target.value) : "")}
                error={_age <= 0}
                helperText={
                  _age <= 0 ? "Yoshni to'g'ri kiriting" : ""
                }
              />
              <TextField
                required
                label="Nechanchi sinf"
                style={ width < 500 ? { width: "100%" } : { width: "200px" }}
                type="text"
                value={_classNumber}
                onChange={(e) => setClassNumber(Number(e.target.value) ? Number(e.target.value) : "")}
                error={_classNumber <= 0}
                helperText={
                  _classNumber <= 0 ? "Sinfni to'g'ri kiriting" : ""
                }
              />
              <TextField
                required
                label="Email"
                style={ width < 500 ? { width: "100%" } : { width: "200px" }}
                value={_email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <TextField
                required
                label="Ism"
                style={ width < 500 ? { width: "100%" } : { width: "200px" }}
                value={_firstName}
                onChange={(e) => setFirstName(e.target.value)}
              />
              <TextField
                required
                label="Familiya"
                style={ width < 500 ? { width: "100%" } : { width: "200px" }}
                value={_lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
              <TextField
                required
                style={ width < 500 ? { width: "100%" } : { width: "200px" }}
                value={_gender}
                onChange={(e) => {
                  setGender(e.target.value);
                }}
                select
                SelectProps={{
                  native: true,
                }}
              >
                <option hidden>Jinsni tanlang</option>
                    <option value="male">Erkak</option>
                    <option value={"female"}>Ayol</option>
              </TextField>
              <TextField
                required
                label="Telefon raqam"
                placeholder="916800819"
                style={ width < 500 ? { width: "100%" } : { width: "200px" }}
                value={_phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
              />
              { width < 500 ? <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Button variant="contained" style={{ width: "100%" }} onClick={createStudent}>
                O`quvchi yaratish
              </Button>
              <Button variant="outlined" style={{ width: "100%" }} onClick={handleClose}>Ortga</Button>
              </div> : <Button variant="contained" style={{ width: "100%" }} onClick={createStudent}>
                O`quvchi yaratish
              </Button>}
            </form>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}

import React, { useState } from "react";
import "./TechNavbar.scss";
import { NavLink, Navigate, useNavigate, useParams } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignOut } from "@fortawesome/free-solid-svg-icons";
import { jwtDecode } from "jwt-decode";
import axios from "../../../lib/axios";

function TestNavbar() {
  const [menu, setMenu] = useState(false);
  // const [check, setCheck] = useState(false);
  const [menuBar, setMenuBar] = useState();
  const groupName = localStorage.getItem("group_name");
  const navigate = useNavigate();
  const { groupId } = useParams();

  const REFRESH_TOKEN = localStorage.getItem("refreshToken");

  // try {
  //   check &&
  //     useEffect(() => {
  //       axios
  //         .get(`token/${REFRESH_TOKEN}`)
  //         .then((response) => {
  //           localStorage.setItem("accessToken", response.data.access_token);
  //           setCheck(false);
  //         })
  //         .catch((err) => {
  //           if (err.response.status === 401) {
  //             window.location.reload();
  //           }
  //           navigate("/logout");
  //           console.log(err);
  //         });
  //     }, [check]);
  // } catch (error) {
  //   return <Navigate to={"/logout"} />;
  // }

  const menuHandler = (e) => {
    setMenu((a) => !a);
    setMenuBar(document.querySelector(".logout"));
    console.dir(menuBar);
  };
  const useAuth = window.localStorage;
  const accessToken = useAuth.getItem("accessToken");
  try {
    if (accessToken !== null) {
      const accessDecode = jwtDecode(accessToken);
      const refreshDecode = jwtDecode(REFRESH_TOKEN);
      if (accessDecode.exp > Date.now() / 1000 || !accessDecode) {
        console.log("Token hali yangi");
      } else if (refreshDecode.exp > Date.now() / 1000) {
        axios
          .get(`token/${REFRESH_TOKEN}`)
          .then((response) => {
            localStorage.setItem("accessToken", response.data.access_token);
            // setCheck(false);
          })
          .catch((err) => {
            if (err.response.status === 401) {
              window.location.reload();
            }
            navigate("/logout");
            console.log(err);
          });
        // setCheck(true);
      } else {
        window.location.reload();
        return <Navigate to={"/logout"} />;
      }
    } else {
      return <Navigate to={"/logout"} />;
    }
  } catch (error) {
    return <Navigate to={"/logout"} />;
  }

  return (
    <>
      <header className="header-section">
        <div className="container">
          <nav className="header__nav">
            <div className="logo__wrapper">
              <h2>
                <NavLink
                  className={"logo"}
                  title="Return to the main page"
                  to={"/tech-group"}
                >
                  {groupName}
                </NavLink>
              </h2>
            </div>
            <ul className="desktop-menu">
              <li>
                <NavLink to={`/${groupId}/tech-lesson`}>Daslar</NavLink>
              </li>
              <li>
                <NavLink to={`/${groupId}/tech-attendance`}>Davomat</NavLink>
              </li>
              <li>
                <NavLink to={`/${groupId}/tech-homework`}>Uyga Vazifa</NavLink>
              </li>
              <li>
                <NavLink to={`/${groupId}/tech-pointers`}>Mukofotlar</NavLink>
              </li>
              <li>
                <NavLink to={`/${groupId}/tech-rating-attendance`}>Reyting(Davomat)</NavLink>
              </li>
              <li>
                <NavLink to={`/${groupId}/tech-rating-homework`}>
                  Reyting (Uyga Vazifa)
                </NavLink>
              </li>
              <li className="d-none menu-bar" onClick={menuHandler}>
                <IconButton edge="start" color="inherit" aria-label="menu">
                  <MenuIcon />
                </IconButton>
              </li>
            </ul>
            {menu && (
              <ul className="mobile-menu">
                <li>
                  <NavLink to={`/${groupId}/tech-lesson`}>Darslar</NavLink>
                </li>
                <li>
                  <NavLink to={`/${groupId}/tech-attendance`}>Davomat</NavLink>
                </li>
                <li>
                  <NavLink to={`/${groupId}/tech-homework`}>Uyga Vazifa</NavLink>
                </li>
                <li>
                  <NavLink to={`/${groupId}/tech-pointers`}>Mukofotlar</NavLink>
                </li>
                <li>
                  <NavLink to={`/${groupId}/tech-rating-attendance`}>Reyting(Davomat)</NavLink>
                </li>
                <li>
                  <NavLink to={`/${groupId}/tech-rating-homework`}>Reyting (Uyga Vazifa)</NavLink>
                  <NavLink className={"block"} to="/logout">
                    <FontAwesomeIcon icon={faSignOut} size="lg" color="green" />
                  </NavLink>
                </li>
              </ul>
            )}
            <NavLink className={"logout"} to="/logout">
              <FontAwesomeIcon icon={faSignOut} size="lg" color="green" />
            </NavLink>
          </nav>
        </div>
      </header>
    </>
  );
}

export default TestNavbar;

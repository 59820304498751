import React, { useEffect, useState } from "react";
import axios from "../../../lib/axios";
import ViewStudent from "./AdminViewGroup";
import "./AdGroup.scss";

import { Delete } from "@mui/icons-material";
import TechAddLesson from "./AdminAddGroup";
import TechUpdateLesson from "./AdminUpdateGroup";
// import { useNavigate } from "react-router-dom";
import AdNavbar from "../AdNavbar/AdNavbar";
import { Link } from "react-router-dom";
import Pagination from "@mui/material/Pagination"; // Import Pagination
import Stack from "@mui/material/Stack"; // Impo

export default function AdGroup() {
  const useAuth = window.localStorage;
  // const navigate = useNavigate();
  const Token = useAuth.getItem("accessToken");
  const [groups, setGroups] = useState();
  const [page, setPage] = useState(1); // Current page state
  const [totalPages, setTotalPages] = useState(1); // Total pages state
  const [limit] = useState(10); // Number of items per page

  const config = {
    headers: {
      Authorization: Token,
    },
  };

  // console.log(group, "group");

  useEffect(() => {
    axios
      .get(`groups?page=${page}&limit=${limit}`, config)
      .then((response) => {
        console.log(response.data);
        setGroups(response.data.groups);
        setTotalPages(response.data.total_count < limit ? 1 : Math.ceil(response.data.total_count / limit)); // Assuming your API returns the total number of pages
      })
      .catch((err) => {
        console.log(err);
      });
  }, [page]);

  const deleteLesson = (id) => {
    console.log(groups);
    if (confirm("O'chirishni istaysizmi?")) {
      axios
        .delete(`group/${id}`, config)
        .then(() => {
          window.location.reload();
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const toggleAction = (e) => {
    const actionBody = e.target.nextSibling;
    console.log(actionBody, "actionBody");
    if (actionBody.style.display === "block") {
      actionBody.style.display = "none";
    } else {
      actionBody.style.display = "block";
    }
  };

  // console.log(groups, "lesson");

  const handlePageChange = (event, value) => {
    setPage(value); // Update the page state when user changes the page
  };

  return (
    <>
      <AdNavbar />
      <main className="mainPage">
        {/* {groups === undefined ? (
          <section className="mygroup-section tech-lesson-section">
          </section>
        ) : ( */}
          <section className="mygroup-section tech-lesson-section admin-group-section ad-teacher">
            <div className="container">
              <div className="mygroup">
                <table className="mygroup__table desktop">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Guruh nomi</th>
                      <th>Boshlangan sanasi</th>
                      <th className="add-wrapper">
                        <TechAddLesson />
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {groups &&
                      groups.map((item, index) => (
                        <tr key={index} id={item.id}>
                          <td>{index + 1}</td>
                          <td id="lessonName">
                            <Link to={`/ad-groups/${item.id}`}>
                              {item.group_name}
                            </Link>
                          </td>
                          <td>
                            <p>{item.start_date.split("T")[0]}</p>
                          </td>
                          <td className="add-wrapper">
                            <ViewStudent data={item} />
                            <TechUpdateLesson data={item} />
                            <button
                              className="btn-delete"
                              onClick={() => deleteLesson(item.id)}
                              style={{ color: "crimson" }}
                            >
                              <Delete />
                            </button>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>

                <div className="mygroup__table mobile">
                  <div className="mygroup__table-head">
                    <div className="">#</div>
                    <div className="">Guruh nomi</div>
                    <TechAddLesson/>
                  </div>
                  <div className="mygroup__table-body">
                    {groups &&
                      groups.map((item, index) => (
                        <div key={index} className="mygroup__table-body--item">
                          <div className=""><p>{index + 1}</p></div>
                          <div className="group-name">
                            <p>
                          <Link to={`/ad-groups/${item.id}`}>
                              {item.group_name}
                            </Link>                          </p>
                          </div>
                            <div>
                              <button className="btn-action"
                                onClick={ toggleAction}>
                                  •••
                              </button>
                              <div className="ad-teacher-action-body">
                                <ViewStudent data={item} />
                                <TechUpdateLesson data={item} />
                                <button
                                  className="btn-delete"
                                  onClick={() => deleteLesson(item.id)}
                                  style={{ color: "crimson" }}
                                >
                                  <Delete />
                                </button>
                              </div>
                            </div>
                        </div>
                      ))}
                  </div>
                </div>
                <Stack spacing={2} alignItems="center" marginTop="20px">
                  <Pagination
                    count={totalPages}
                    page={page}
                    onChange={handlePageChange}
                    color="secondary"
                    variant="outlined"
                    shape="rounded"
                  />
                </Stack>
              </div>
            </div>
          </section>
        {/* )} */}
      </main>
    </>
  );
}

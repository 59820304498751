import React, { useEffect, useState } from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import { Edit } from "@mui/icons-material";
import axios from "../../../lib/axios";
import TextField from "@mui/material/TextField";
import useWindowDimensions from "../../../hooks/useWindowDimensions";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: 500,
  width: "100%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  zIndex: 10,
};

export default function AdminUpdateTeacher(item) {
  const [open, setOpen] = useState(false);
  const Token = localStorage.getItem("accessToken");
  const [disabled, setDisabled] = useState(true);
  const [id] = useState(item.id);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [age, setAge] = useState();
  const [experience, setExperience] = useState();
  const [email, setEmail] = useState("");
  const [bio, setBio] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const { width } = useWindowDimensions();
  console.log(width);
  // console.log(item.data);
  console.log(window);

  const config = {
    headers: {
      Authorization: Token,
    },
  };

  console.log(item.data);

  const updateTeacher = (e) => {
    e.preventDefault();
    const formData = {
      age,
      experience,
      email,
      first_name: firstName,
      bio,
      id: item.data.id,
      last_name: lastName,
      phone_number: phoneNumber,
    };

    axios
      .put("/teacher", formData, config)
      .then((response) => {
        console.log(response.data);
        window.location.reload();
      })
      .catch((err) => {
        console.log(err);
      });

    console.log(formData);
  };

  useEffect(() => {
    if (age && experience && email && firstName && bio && id && lastName && phoneNumber) {
      setDisabled(false);
    }
  }, [disabled]);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
      <Button onClick={handleOpen}>
        <Edit />
      </Button>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box component="form" sx={style} noValidate autoComplete="off">
            <form
              style={ width < 500 ? {
                display: "flex",
                flexDirection: "column",
                rowGap: "20px",
              } : {
                display: "flex",
                flexWrap: "wrap",
                rowGap: "20px",
                columnGap: "20px",
                alignItems: "center",
                justifyContent: "center"
              }}
            >
              <TextField
                required
                label="Yosh"
                style={ width < 500 ? { width: "100%" } : { width: "200px" }}
                type="text"
                value={age}
                onChange={(e) => setAge(Number(e.target.value))}
              />
              <TextField
                required
                label="Tajribasi qancha?"
                style={ width < 500 ? { width: "100%" } : { width: "200px" }}
                type="text"
                value={experience}
                onChange={(e) => setExperience(Number(e.target.value))}
              />
              <TextField
                required
                label="Elektron pochta"
                style={ width < 500 ? { width: "100%" } : { width: "200px" }}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <TextField
                required
                label="Ism"
                style={ width < 500 ? { width: "100%" } : { width: "200px" }}
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
              />
              <TextField
              required
              label="Haqida"
              style={ width < 500 ? { width: "100%" } : { width: "200px" }}
              value={bio}
              onChange={(e) => setBio(e.target.value)}
            />
              <TextField
                required
                label="Familiya"
                style={ width < 500 ? { width: "100%" } : { width: "200px" }}
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
              <TextField
                required
                label="Telefon raqami"
                style={ width < 500 ? { width: "100%" } : { width: "200px" }}
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
              />
              { width < 500 ? <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Button variant="contained" style={{ width: "100%" }} onClick={updateTeacher}>
                Yaratish
              </Button>
              <Button variant="outlined" style={{ width: "100%" }} onClick={handleClose}>Ortga</Button>
              </div> : <Button variant="contained" style={{ width: "100%" }} onClick={updateTeacher}>
                Yaratish
              </Button> }
            </form>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}

import React, { useEffect, useState } from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import { Edit } from "@mui/icons-material";
import axios from "../../../lib/axios";
import TextField from "@mui/material/TextField";
import moment from "moment";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
// import { makeStyles } from "@mui/styles";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: 500,
  width: "100%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  zIndex: 10,
};

// const useStyles = makeStyles({
//   noBorder: {
//     "& .MuiOutlinedInput-root": {
//       "& fieldset": {
//         border: "none",
//       },
//     },
//   },
//   hiddenIcon: {
//     "&::-webkit-calendar-picker-indicator": {
//       display: "none",
//     },
//   },
// });

export default function AdminUpdateGroup(item) {
  // const classes = useStyles();
  const [open, setOpen] = useState(false);
  const Token = localStorage.getItem("accessToken");
  const [disabled, setDisabled] = useState(true);
  const [id] = useState(item.id);
  const [groupName, setGroupName] = useState("");
  const [tagName, setTagName] = useState("");
  const [teacherName, setTeacherName] = useState("");
  const [startDate, setStartDate] = useState("");
  const [startTime, setStartTime] = useState("");
  const [endDate, setEndDate] = useState("");
  const [endTime, setEndTime] = useState("");
  const [teacherId, setTeacherId] = useState("");
  const [teacher, setTeacher] = useState();
  const { width } = useWindowDimensions();

  const config = {
    headers: {
      Authorization: Token,
    },
  };

  useEffect(() => {
    axios.get("/teachers?page=1&limit=50", config).then((response) => {
      setTeacher(response.data.teachers);
    });
  }, []);

  // console.log(item.data);

  const updateStudent = (e) => {
    e.preventDefault();
    const formData = {
      teacher_name: teacherName,
      start_date: moment(startDate).format("MM/DD/YYYY"),
      start_time: startTime,
      group_name: groupName,
      end_date: moment(endDate).format("MM/DD/YYYY"),
      id: item.data.id,
      tag_name: tagName,
      end_time: endTime,
      teacher_id: teacherId,
    };
    // console.log(formData);

    axios
      .put("/group", formData, config)
      .then((response) => {
        // console.log(response.data);
        window.location.reload();
      })
      .catch((err) => {
        console.log(err);
      });

    console.log(formData);
  };

  useEffect(() => {
    if (teacherName && startDate && startTime && groupName && endDate && id && tagName && endTime && teacherId) {
      setDisabled(false);
    }
  }, [disabled]);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
      <Button onClick={handleOpen}>
        <Edit />
      </Button>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box component="form" sx={style} noValidate autoComplete="off">
            <div
              className="admin-group-section__noBorder"
              style={{
                display: "flex",
                flexWrap: "wrap",
                rowGap: "20px",
                columnGap: "20px",
              }}
            >
            <TextField
              required
              label="Guruh nomi"
                style={ width < 500 ? { width: "100%" } : { width: "200px" }}

              value={groupName}
              onChange={(e) => setGroupName(e.target.value)}
            />
            <TextField
              required
              label="Yo`nalish"
                style={ width < 500 ? { width: "100%" } : { width: "200px" }}

              value={tagName}
              onChange={(e) => setTagName(e.target.value)}
            />
              {/* <TextField
                required
                label="O`qituvchi ismi"
                style={ width < 500 ? { width: "100%" } : { width: "200px" }}
                value={teacherName}
                onChange={(e) => setTeacherName((e.target.value))}
              /> */}
              <TextField
                required
                style={ width < 500 ? { width: "100%" } : { width: "200px" }}
                value={teacherName}
                onChange={(e) => {
                  setTeacherId(e.target.value.split("|")[0]);
                  setTeacherName(e.target.value.split("|")[1]);
                }}
                select
                SelectProps={{
                  native: true,
                }}
              >
                <option hidden>O`qituvchi tanlang</option>
                {teacher && teacher.map((item) => (
                  <option key={item.id} value={item.id}>
                    {item.first_name + " " + item.last_name}
                  </option>
                ))}
              </TextField>

              <TextField
                required
                focused
                // className={`${classes.noBorder} ${classes.hiddenIcon}`}
                label="Boshlangan sana"
                style={ width < 500 ? { width: "100%" } : { width: "200px" }}
                value={startDate}
                type="date"
                onChange={(e) => setStartDate((e.target.value))}
              />
              <TextField
                required
                focused
                className="noBorder"
                label="Boshlanish vaqti"
                style={ width < 500 ? { width: "100%" } : { width: "200px" }}
                value={startTime}
                type="time"
                onChange={(e) => setStartTime(e.target.value)}
              />
              <TextField
              required
              focused
              className="noBorder"
              label="Tugagan sana"
                style={ width < 500 ? { width: "100%" } : { width: "200px" }}

              value={endDate}
              type="date"
              onChange={(e) => setEndDate(e.target.value)}
            />
              <TextField
                required
                focused
                className="noBorder"
                label="Tugash vaqti"
                style={ width < 500 ? { width: "100%" } : { width: "200px" }}
                value={endTime}
                type="time"
                onChange={(e) => setEndTime(e.target.value)}
              />
              <TextField
                required
                label="O`qituvchi ID"
                style={{ width: "200px", display: "none" }}
                value={teacherId}
                onChange={(e) => setTeacherId(e.target.value)}
              />
              <Button variant="contained" style={{ width: "100%" }} onClick={updateStudent}>
                Guruhni yangilash
              </Button>
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}

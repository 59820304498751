import React, { useState } from "react";
import "./Header.scss";
import logo from "../../assets/logo/logo.png";
import { NavLink, Navigate } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignOut } from "@fortawesome/free-solid-svg-icons";
import { jwtDecode } from "jwt-decode";
import axios from "../../lib/axios";

function Header() {
  const [menu, setMenu] = useState(false);
  const [data, setData] = useState([]);
  // const [check, setCheck] = useState(false);
  const [menuBar, setMenuBar] = useState();

  const REFRESH_TOKEN = localStorage.getItem("refreshToken");
  const ROLE = localStorage.getItem("role");

  // useEffect(() => {
  //   axios
  //     .get(`token/${REFRESH_TOKEN}`)
  //     .then((response) => {
  //       localStorage.setItem("accessToken", response.data.access_token);
  //       setData(response.data);
  //       setCheck(false);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // }, [check]);

  console.log(data);

  const menuHandler = (e) => {
    setMenu((a) => !a);
    setMenuBar(document.querySelector(".logout"));
    console.dir(menuBar);
    console.log(menu);
  };

  const useAuth = window.localStorage;
  const accessToken = useAuth.getItem("accessToken");
  try {
    if (accessToken !== null) {
      const accessDecode = jwtDecode(accessToken);
      const refreshDecode = jwtDecode(REFRESH_TOKEN);
      console.log(refreshDecode);

      if (accessDecode.exp > Date.now() / 1000 || !accessDecode) {
        // axios
        //   .get(`token/${REFRESH_TOKEN}`)
        //   .then((response) => {
        //     localStorage.setItem("accessToken", response.data.access_token);
        //     setData(response.data);
        //     console.log(response);
        //     // setCheck(false);
        //   })
        //   .catch((err) => {
        //     console.log(err);
        //   });
        console.log("Token hali yangi");
      } else if (refreshDecode.exp > Date.now() / 1000) {
        // console.log(refreshDecode);
        axios
          .get(`token/${REFRESH_TOKEN}`)
          .then((response) => {
            localStorage.setItem("accessToken", response.data.access_token);
            setData(response.data);
            console.log(response);
            // setCheck(false);
          })
          .catch((err) => {
            console.log(err);
          });
        // setCheck(true);
      } else {
        // window.location.reload();
        return <Navigate to={"/logout"} />;
      }
    } else {
      return <Navigate to={"/logout"} />;
    }
  } catch (error) {
    console.log(error);
    return <Navigate to={"/logout"} />;
  }

  return (
    <>
      <header className="header-section">
        <div className="container">
          <nav className="header__nav">
            <div className="logo__wrapper">
              <NavLink className={"logo__link"}>
                <img className="header__logo" src={logo} alt="Site logo" />
              </NavLink>
            </div>
            <ul className="desktop-menu">
              {ROLE === "student" ? (
                <>
                  <li>
                    <NavLink to="/">Bosh sahifa</NavLink>
                  </li>
                  <li>
                    <NavLink to="/payments">To`lovlarim</NavLink>
                  </li>
                  <li>
                    <NavLink to="/my-groups">Guruhlarim</NavLink>
                  </li>
                  <li>
                    <NavLink to="/pointers">Ko`rsatgichlarim</NavLink>
                  </li>
                  <li>
                    <NavLink to="/rating">Reyting</NavLink>
                  </li>
                  <li>
                    <NavLink to="/profile">Sozlamalar</NavLink>
                  </li>
                </>
              ) : ROLE === "teacher" ? (
                <>
                  <li>
                    <NavLink to="/tech-group">Guruhlarim</NavLink>
                  </li>
                  <li>
                    <NavLink to="/profile">Sozlamalar</NavLink>
                  </li>
                </>
              ) : ROLE === "admin" && (
                <>
                {/* <li>
                  <NavLink to="/ad-students">Talabalar</NavLink>
                </li>
                <li>
                  <NavLink to="/ad-teachers">O`qituvchilar</NavLink>
                </li>
                <li>
                  <NavLink to="/ad-groups">Guruhlar</NavLink>
                </li>
                <li>
                  <NavLink to="/ad-tests">Testlar</NavLink>
                </li>
                <li>
                  <NavLink to="/ad-payments">To`lovlar</NavLink>
                </li>
                <li>
                  <NavLink to="/ad-costs">Xarajatlar</NavLink>
                </li>
                <li>
                  <NavLink to="/ad-rewards">Mukofotlar</NavLink>
                </li> */}
                </>
              )}
              <li className="d-none menu-bar" onClick={menuHandler}>
                <IconButton edge="start" color="inherit" aria-label="menu">
                  <MenuIcon />
                </IconButton>
              </li>
            </ul>
            {ROLE === "student" ? (
              menu && (
                <ul className="mobile-menu">
                <li>
                  <NavLink to="/">Bosh sahifa</NavLink>
                </li>
                <li>
                  <NavLink to="/payments">To`lovlarim</NavLink>
                </li>
                <li>
                  <NavLink to="/my-groups">Guruhlarim</NavLink>
                </li>
                <li>
                  <NavLink to="/pointers">Ko`rsatgichlarim</NavLink>
                </li>
                <li>
                  <NavLink to="/rating">Reyting</NavLink>
                </li>
                <li>
                  <NavLink className="options" to="/profile">
                    Sozlamalar
                  </NavLink>
                  <NavLink className={"logou"} to="/logout">
                  <FontAwesomeIcon icon={faSignOut} size="lg" color="green" />
                </NavLink>
                </li>
              </ul>
              )
            ) : (menu && <ul className="teacher">
              <li>
                <NavLink to="/tech-group">Guruhlarim</NavLink>
              </li>
              <li>
                <NavLink className="options" to="/profile">
                  Sozlamalar
                </NavLink>
                <NavLink className={"logout"} to="/logout">
                  <FontAwesomeIcon icon={faSignOut} size="lg" color="green" />
                </NavLink>
              </li>
            </ul>)}
            <NavLink className={"logout"} to="/logout">
              <FontAwesomeIcon icon={faSignOut} size="lg" color="green" />
            </NavLink>
          </nav>
        </div>
      </header>
    </>
  );
}

export default Header;
